import Footer from "../component/Footer";
import Header from "../component/Header";

import DashLogo from "./../assets-dream/img/icons/dashboard-icon.svg"
import MyBookingLogo from "./../assets-dream/img/icons/booking-icon.svg"
import ProfileLogo from "./../assets-dream/img/icons/profile-icon.svg"
import ChatLogo from "./../assets-dream/img/icons/chat-icon.svg"
import StateLogo from "./../assets-dream/img/icons/statistics-01.svg"
import { useEffect, useState } from "react";
import APIS from "../apis";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, Logout } from "../uti/uti";
import moment from "moment";
import { useSelector } from "react-redux";
import DashboardHead from "../component/DashboardHead";

export default function UserDashboard() {

  const navigate = useNavigate()

  const token = useSelector(state => state?.auth?.token)

  const [data, Setdata] = useState([])

    useEffect(() => {

      if(token){
        LoadMyBooking()
      }
      
    }, [token])

    const LoadMyBooking = async () => {
        const res = await APIS.Ground.MyBookings()
        console.log(res)
        if(res){
          Setdata(res?.result)
        }
    }

  return (
    <>
      <Header />
      <div className="" style={{marginTop:80}} >
        
        <DashboardHead 
          screenName="dashboard"
        />
        
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="card dashboard-card statistics-card">
                  <div className="card-header">
                    <h4>Statistics</h4>
                    <p>Boost your game with stats and goals tailored to you</p>
                  </div>
                  <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-6 d-flex">
                      <div className="statistics-grid flex-fill">
                        <div className="statistics-content">
                          <h3>{data?.length}</h3>
                          <p>Total Ground Booked</p>
                        </div>
                        <div className="statistics-icon">
                          <img src={StateLogo} alt="Icon" />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-xl-3 col-lg-6 col-md-6 d-flex">
                      <div className="statistics-grid flex-fill">
                        <div className="statistics-content">
                          <h3>45</h3>
                          <p>Total Coaches Booked</p>
                        </div>
                        <div className="statistics-icon">
                          <img src="assets/img/icons/statistics-02.svg" alt="Icon" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 d-flex">
                      <div className="statistics-grid flex-fill">
                        <div className="statistics-content">
                          <h3>06</h3>
                          <p>Total Lessons</p>
                        </div>
                        <div className="statistics-icon">
                          <img src="assets/img/icons/statistics-03.svg" alt="Icon" />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 d-flex">
                      <div className="statistics-grid flex-fill">
                        <div className="statistics-content">
                          <h3>$45,056</h3>
                          <p>Payments</p>
                        </div>
                        <div className="statistics-icon">
                          <img src="assets/img/icons/statistics-04.svg" alt="Icon" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="card dashboard-card">
                  <div className="card-header">
                    <h4>Todays Appointment</h4>
                    <p>Your Personal Badminton Schedule</p>
                  </div>
                  <div className="appointment-info">
                    <ul>
                      <li>
                        <div className="appointment-item">
                          <div className="appointment-img">
                            <img
                              src="assets/img/booking/booking-01.jpg"
                              alt="Appointment"
                            />
                          </div>
                          <div className="appointment-content">
                            <h6>Court Name</h6>
                            <p>Standard Synthetic Court 1</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <h6>Appointment Date</h6>
                        <p>Mon, Jul 11</p>
                      </li>
                      <li>
                        <h6>Start Time</h6>
                        <p>05:25 AM</p>
                      </li>
                      <li>
                        <h6>Appointment End Time</h6>
                        <p>06:25 AM</p>
                      </li>
                      <li>
                        <h6>Additional Guests</h6>
                        <p>4</p>
                      </li>
                      <li>
                        <h6>Location</h6>
                        <p>Sant Marco</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-xl-12 col-lg-12 d-flex">
                <div className="card dashboard-card flex-fill">
                  <div className="card-header card-header-info">
                    <div className="card-header-inner">
                      <h4>My Bookings</h4>
                      <p>Ground Reservations Made Easy</p>
                    </div>
                    {/* <div className="card-header-btns">
                      <nav>
                        <div className="nav nav-tabs" role="tablist">
                          <button
                            className="nav-link active"
                            id="nav-Court-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Court"
                            type="button"
                            role="tab"
                            aria-controls="nav-Court"
                            aria-selected="true"
                          >
                            Court
                          </button>
                          <button
                            className="nav-link"
                            id="nav-Coaching-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Coaching"
                            type="button"
                            role="tab"
                            aria-controls="nav-Coaching"
                            aria-selected="false"
                          >
                            Coaching
                          </button>
                        </div>
                      </nav>
                    </div> */}
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="nav-Court"
                      role="tabpanel"
                      aria-labelledby="nav-Court-tab"
                      tabIndex={0}
                    >
                      <div className="table-responsive dashboard-table-responsive">
                        <table className="table dashboard-card-table">
                          <tbody>
                            {data?.map((ele, index) => 
                              <tr
                                key={index}
                              >
                                <td>
                                  <div className="academy-info">
                                    <Link
                                      href="user-bookings.html"
                                      className="academy-img"
                                    >
                                      <img
                                        src={BASE_URL + "/" + ele?.ground?.gallery[0]}

                                        alt="Booking"
                                      />
                                    </Link>
                                    <div className="academy-content">
                                      <h6>
                                        <a
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#upcoming-court"
                                        >
                                          {ele?.ground?.name}
                                        </a>
                                      </h6>
                                      {/* <span>Court 1</span> */}
                                      <ul>
                                        {/* <li>Guests : 4</li> */}
                                        <li>{ele?.totalSlots} Slots</li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h6>Date &amp; Time</h6>
                                  <p>{moment(ele?.startDate).format("MMM DD, YYYY")}</p>
                                  <p>{moment(ele?.startDate).format("hh:mm A")} - {moment(ele?.endDate).format("hh:mm A")}</p>
                                </td>
                                <td>
                                  <h4>৳{parseFloat(ele?.totalCharge).toFixed(2)}</h4>
                                </td>
                                <td>
                                  <h4>{ele?.bookingStatus}</h4>
                                  {/* <div className="dropdown dropdown-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="feather-x-circle" /> Cancel
                                      </a>
                                    </div>
                                  </div> */}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-5 col-lg-12 d-flex flex-column">
                <div className="card payment-card ">
                  <div className="payment-info ">
                    <div className="payment-content">
                      <p>Your Wallet Balance</p>
                      <h2>$4,544</h2>
                    </div>
                    <div className="payment-btn">
                      <a
                        href="javascript:void(0);"
                        className="btn"
                        data-bs-toggle="modal"
                        data-bs-target="#add-payment"
                      >
                        Add Payment
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card dashboard-card upcoming-card">
                  <div className="card-header card-header-info">
                    <div className="card-header-inner">
                      <h4>Upcoming Appointment</h4>
                      <p>Manage all your upcoming court bookings.</p>
                    </div>
                    <div className="card-header-btns">
                      <nav>
                        <div className="nav nav-tabs" role="tablist">
                          <button
                            className="nav-link active"
                            id="nav-Appointment-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Appointment"
                            type="button"
                            role="tab"
                            aria-controls="nav-Appointment"
                            aria-selected="true"
                          >
                            Court
                          </button>
                          <button
                            className="nav-link"
                            id="nav-AppointmentCoaching-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-AppointmentCoaching"
                            type="button"
                            role="tab"
                            aria-controls="nav-AppointmentCoaching"
                            aria-selected="false"
                          >
                            Coaching
                          </button>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="nav-Appointment"
                      role="tabpanel"
                      aria-labelledby="nav-Appointment-tab"
                      tabIndex={0}
                    >
                      <div className="table-responsive dashboard-table-responsive">
                        <table className="table dashboard-card-table">
                          <tbody>
                            <tr>
                              <td>
                                <div className="academy-info academy-info">
                                  <a
                                    href="user-bookings.html"
                                    className="academy-img"
                                  >
                                    <img
                                      src="assets/img/booking/booking-02.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a
                                        data-bs-toggle="modal"
                                        data-bs-target="#upcoming-court"
                                      >
                                        Leap Sports Academy
                                      </a>
                                    </h6>
                                    <ul>
                                      <li>Court 1</li>
                                      <li>
                                        <i className="feather-clock" /> 06:00 PM to
                                        08:00 PM
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-x-circle" /> Cancel
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-AppointmentCoaching"
                      role="tabpanel"
                      aria-labelledby="nav-AppointmentCoaching-tab"
                      tabIndex={0}
                    >
                      <div className="table-responsive dashboard-table-responsive">
                        <table className="table dashboard-card-table">
                          <tbody>
                            <tr>
                              <td>
                                <div className="academy-info academy-info">
                                  <a
                                    href="user-bookings.html"
                                    className="academy-img"
                                  >
                                    <img
                                      src="assets/img/featured/featured-05.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a
                                        data-bs-toggle="modal"
                                        data-bs-target="#upcoming-coach"
                                      >
                                        Kevin Anderson
                                      </a>
                                    </h6>
                                    <ul>
                                      <li>Single Lesson</li>
                                      <li>
                                        <i className="feather-clock" /> 06:00 PM to
                                        08:00 PM
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-x-circle" /> Cancel
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card dashboard-card academy-card">
                  <div className="card-header card-header-info">
                    <div className="card-header-inner">
                      <h4>My Favourites</h4>
                      <p>My favourite court lists </p>
                    </div>
                    <div className="card-header-btns">
                      <nav>
                        <div className="nav nav-tabs" role="tablist">
                          <button
                            className="nav-link active"
                            id="nav-Favourites-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Favourites"
                            type="button"
                            role="tab"
                            aria-controls="nav-Favourites"
                            aria-selected="true"
                          >
                            Court
                          </button>
                          <button
                            className="nav-link"
                            id="nav-FavouritesCoaching-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-FavouritesCoaching"
                            type="button"
                            role="tab"
                            aria-controls="nav-FavouritesCoaching"
                            aria-selected="false"
                          >
                            Coaching
                          </button>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="nav-Favourites"
                      role="tabpanel"
                      aria-labelledby="nav-Favourites-tab"
                      tabIndex={0}
                    >
                      <div className="table-responsive dashboard-table-responsive">
                        <table className="table dashboard-card-table">
                          <tbody>
                            <tr>
                              <td>
                                <div className="academy-info academy-info">
                                  <a
                                    href="user-bookings.html"
                                    className="academy-img"
                                  >
                                    <img
                                      src="assets/img/booking/booking-03.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a href="user-bookings.html">
                                        Wing Sports Academy
                                      </a>
                                    </h6>
                                    <p>10 Bookings</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="academy-icon">
                                  <a href="user-bookings.html">
                                    <i className="feather-chevron-right" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="academy-info academy-info">
                                  <a
                                    href="user-bookings.html"
                                    className="academy-img"
                                  >
                                    <img
                                      src="assets/img/booking/booking-04.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a href="user-bookings.html">
                                        Feather Badminton
                                      </a>
                                    </h6>
                                    <p>20 Bookings</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="academy-icon">
                                  <a href="user-bookings.html">
                                    <i className="feather-chevron-right" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="academy-info academy-info">
                                  <a
                                    href="user-bookings.html"
                                    className="academy-img"
                                  >
                                    <img
                                      src="assets/img/booking/booking-05.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a href="user-bookings.html">
                                        Bwing Sports Academy
                                      </a>
                                    </h6>
                                    <p>30 Bookings</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="academy-icon">
                                  <a href="user-bookings.html">
                                    <i className="feather-chevron-right" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-FavouritesCoaching"
                      role="tabpanel"
                      aria-labelledby="nav-FavouritesCoaching-tab"
                      tabIndex={0}
                    >
                      <div className="table-responsive dashboard-table-responsive">
                        <table className="table dashboard-card-table">
                          <tbody>
                            <tr>
                              <td>
                                <div className="academy-info academy-info">
                                  <a
                                    href="user-bookings.html"
                                    className="academy-img"
                                  >
                                    <img
                                      src="assets/img/featured/featured-05.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a href="user-bookings.html">Kevin Anderson</a>
                                    </h6>
                                    <p>10 Bookings</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="academy-icon">
                                  <a href="user-bookings.html">
                                    <i className="feather-chevron-right" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="academy-info academy-info">
                                  <a
                                    href="user-bookings.html"
                                    className="academy-img"
                                  >
                                    <img
                                      src="assets/img/featured/featured-06.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a href="user-bookings.html">
                                        Angela Roudrigez
                                      </a>
                                    </h6>
                                    <p>20 Bookings</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="academy-icon">
                                  <a href="user-bookings.html">
                                    <i className="feather-chevron-right" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="academy-info academy-info">
                                  <a
                                    href="user-bookings.html"
                                    className="academy-img"
                                  >
                                    <img
                                      src="assets/img/featured/featured-07.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <div className="academy-content">
                                    <h6>
                                      <a href="user-bookings.html">Evon Raddick</a>
                                    </h6>
                                    <p>30 Bookings</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="academy-icon">
                                  <a href="user-bookings.html">
                                    <i className="feather-chevron-right" />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="card dashboard-card mb-0">
                  <div className="card-header card-header-info border-0">
                    <div className="card-header-inner">
                      <h4>Recent Invoices</h4>
                      <p>Access recent invoices related to court bookings </p>
                    </div>
                    <div className="card-header-btns">
                      <nav>
                        <div className="nav nav-tabs" role="tablist">
                          <button
                            className="nav-link active"
                            id="nav-Recent-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-Recent"
                            type="button"
                            role="tab"
                            aria-controls="nav-Recent"
                            aria-selected="true"
                          >
                            Court
                          </button>
                          <button
                            className="nav-link"
                            id="nav-RecentCoaching-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-RecentCoaching"
                            type="button"
                            role="tab"
                            aria-controls="nav-RecentCoaching"
                            aria-selected="false"
                          >
                            Coaching
                          </button>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="nav-Recent"
                      role="tabpanel"
                      aria-labelledby="nav-Recent-tab"
                      tabIndex={0}
                    >
                      <div className="table-responsive table-datatble">
                        <table className="table table-borderless dashboard-card-table">
                          <thead className="thead-light">
                            <tr>
                              <th>Court Name</th>
                              <th>Date &amp; Time</th>
                              <th>Payment</th>
                              <th>Paid On</th>
                              <th>Status</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/booking/booking-02.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-court"
                                    >
                                      Leap Sports Academy
                                    </a>
                                    <span>Court 1</span>
                                  </span>
                                </h2>
                              </td>
                              <td>
                                <p>Mon, Jul 11</p>
                                <p>06:00 PM - 08:00 PM</p>
                              </td>
                              <td>
                                <h6>$800</h6>
                              </td>
                              <td>Jul 11, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/booking/booking-03.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-court"
                                    >
                                      Wing Sports Academy
                                    </a>
                                    <span>Court 2</span>
                                  </span>
                                </h2>
                              </td>
                              <td>
                                <p>Tue, Jul 12</p>
                                <p>05:00 PM - 06:00 PM</p>
                              </td>
                              <td>
                                <h6>$120</h6>
                              </td>
                              <td>Jul 12, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/booking/booking-04.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-court"
                                    >
                                      Feather Badminton
                                    </a>
                                    <span>Court 3</span>
                                  </span>
                                </h2>
                              </td>
                              <td>
                                <p>Wed, Jul 13</p>
                                <p>10:00 AM - 11:00 AM</p>
                              </td>
                              <td>
                                <h6>$470</h6>
                              </td>
                              <td>Jul 13, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/booking/booking-05.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-court"
                                    >
                                      Bwing Sports Academy
                                    </a>
                                    <span>Court 4</span>
                                  </span>
                                </h2>
                              </td>
                              <td>
                                <p>Thu, Jul 14</p>
                                <p>12:00 PM - 01:00 PM</p>
                              </td>
                              <td>
                                <h6>$200</h6>
                              </td>
                              <td>Jul 14, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-court"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/booking/booking-06.jpg"
                                      alt="Booking"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-court"
                                    >
                                      Marsh Academy
                                    </a>
                                    <span>Court 5</span>
                                  </span>
                                </h2>
                              </td>
                              <td>
                                <p>Fri, Jul 15</p>
                                <p>08:00 AM - 09:00 AM</p>
                              </td>
                              <td>
                                <h6>$150</h6>
                              </td>
                              <td>Jul 15, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-RecentCoaching"
                      role="tabpanel"
                      aria-labelledby="nav-RecentCoaching-tab"
                      tabIndex={0}
                    >
                      <div className="table-responsive table-datatble">
                        <table className="table table-borderless dashboard-card-table">
                          <thead className="thead-light">
                            <tr>
                              <th>Court Name</th>
                              <th>Invoice</th>
                              <th>Date &amp; Time</th>
                              <th>Payment</th>
                              <th>Paid On</th>
                              <th>Status</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-coach"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/featured/featured-05.jpg"
                                      alt="Venue"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-coach"
                                    >
                                      Kevin Anderson
                                    </a>
                                    <span>Booked on : 25 May 2023</span>
                                  </span>
                                </h2>
                              </td>
                              <td>Onetime</td>
                              <td>
                                <p>Mon, Jul 11</p>
                                <p>06:00 PM - 08:00 PM</p>
                              </td>
                              <td>
                                <h6>$800</h6>
                              </td>
                              <td>Jul 11, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-coach"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/featured/featured-06.jpg"
                                      alt="Venue"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-coach"
                                    >
                                      Angela Roudrigez
                                    </a>
                                    <span>Booked on : 26 May 2023</span>
                                  </span>
                                </h2>
                              </td>
                              <td>Single Lesson</td>
                              <td>
                                <p>Tue, Jul 12</p>
                                <p>05:00 PM - 06:00 PM</p>
                              </td>
                              <td>
                                <h6>$120</h6>
                              </td>
                              <td>Jul 12, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-coach"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/featured/featured-07.jpg"
                                      alt="Venue"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-coach"
                                    >
                                      Evon Raddickz
                                    </a>
                                    <span>Booked on : 27 May 2023</span>
                                  </span>
                                </h2>
                              </td>
                              <td>Onetime</td>
                              <td>
                                <p>Wed, Jul 13</p>
                                <p>10:00 AM - 11:00 AM</p>
                              </td>
                              <td>
                                <h6>$470</h6>
                              </td>
                              <td>Jul 13, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    data-bs-target="#upcoming-coach"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#upcoming-coach"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/featured/featured-08.jpg"
                                      alt="Venue"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a
                                      href="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#upcoming-coach"
                                    >
                                      Harry Richardson
                                    </a>
                                    <span>Booked on : 28 May 2023</span>
                                  </span>
                                </h2>
                              </td>
                              <td>Onetime</td>
                              <td>
                                <p>Thu, Jul 14</p>
                                <p>12:00 PM - 01:00 PM</p>
                              </td>
                              <td>
                                <h6>$200</h6>
                              </td>
                              <td>Jul 14, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <a
                                    href="user-invoice.html"
                                    className="avatar avatar-sm flex-shrink-0"
                                  >
                                    <img
                                      className="avatar-img"
                                      src="assets/img/featured/featured-09.jpg"
                                      alt="Venue"
                                    />
                                  </a>
                                  <span className="table-head-name flex-grow-1">
                                    <a href="user-invoice.html">Pete Hill</a>
                                    <span>Booked on : 29 May 2023</span>
                                  </span>
                                </h2>
                              </td>
                              <td>Onetime</td>
                              <td>
                                <p>08:00 AM - 09:00 AM</p>
                              </td>
                              <td>
                                <h6>$150</h6>
                              </td>
                              <td>Jul 15, 2023</td>
                              <td className="paid-edit">
                                <span>
                                  <i className="feather-edit" /> Paid
                                </span>
                              </td>
                              <td>
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-download" /> Download
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="feather-trash" /> Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>

  )
}