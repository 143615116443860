import Carousel from "react-multi-carousel";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { CarouselResponsive } from "../uti/uti";

import Banner1 from './../assets-dream/img/aboutus/banner-01.jpg'
import Banner2 from './../assets-dream/img/aboutus/banner-02.jpg'
import Banner3 from './../assets-dream/img/aboutus/banner-03.jpg'

import Team1 from './../assets-dream/img/aboutus/team-05.jpg'
import Ground1 from './../assets/images/ground1.png'
import Ground2 from './../assets/images/ground2.png'
import Ground3 from './../assets/images/ground3.png'


export default function AboutUs() {


    return (
        <>
            <Header />

            <div className="content listing-list-page" style={{ marginTop: 100 }} >
                <section className="aboutus-info">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className=" col-12 col-sm-3 col-md-3 col-lg-3">
                                <div className="banner text-center">
                                    <img
                                        src={Banner1}
                                        className="img-fluid corner-radius-10"
                                        alt="Banner-01"
                                    />
                                </div>
                            </div>
                            <div className=" col-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="banner text-center">
                                    <img
                                        src={Banner2}
                                        className="img-fluid corner-radius-10"
                                        alt="Banner-02"
                                    />
                                </div>
                            </div>
                            <div className=" col-12 col-sm-3 col-md-3 col-lg-3">
                                <div className="banner text-center">
                                    <img
                                        src={Banner3}
                                        className="img-fluid corner-radius-10"
                                        alt="Banner-03"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="vision-mission">
                            <div className="row">
                                <div className=" col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mission-bg">
                                        <h2>Our Vision</h2>
                                        <p>
                                        Our vision is to become the ultimate destination for athletes and enthusiasts, offering a comprehensive range of world-class facilities and services that inspire excellence. 
                                        </p>
                                        <p>
                                        We aspire to ignite a deep and enduring passion for sports in every heart that beats for the game.
                                        </p>
                                    </div>
                                </div>
                                <div className=" col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="mission-bg">
                                        <h2>Our Mission</h2>
                                        <p>
                                        Our mission is clear - to create a dynamic sporting environment that not only nurtures skill development but also fosters teamwork, camaraderie, and a lifelong love for sports among our community members. We aim to be the catalyst for positive change in people's lives through sports.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section ourteam dull-bg" style={{display:'none'}}>
                    <div className="container">
                        <div className="section-heading">
                            <h2>
                                Our <span>Team</span>
                            </h2>
                            <p className="sub-title">
                                Our team united by passion, driven by excellence.
                            </p>
                        </div>
                        <Carousel
                            responsive={CarouselResponsive}
                            infinite
                            draggable={false}
                            autoPlay
                            num
                        >
                            {[0,0,0,0,0,0].map((ele, index) =>
                                <div className="team-item" style={{padding:15}} >
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    // src="assets/img/aboutus/team-01.jpg"
                                                    src={Team1}
                                                    className="img-fluid"
                                                    alt="Team-01"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Caterine</h4>
                                                <h6>Chief Executive Officer</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Caterine</h4>
                                                <h6>Chief Executive Officer</h6>
                                            </div>
                                            <p>
                                                Results-driven CEO, Ms. Caterine, fosters innovation and
                                                drives growth with proven success. Her strategic vision,
                                                collaborative leadership, and financial acumen consistently
                                                deliver excellence, value, and strong stakeholder
                                                relationships.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </Carousel>
                        {/* <div className="featured-slider-group p-0">
                            <div className="owl-carousel team-slider owl-theme">
                                <div className="team-item">
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    src="assets/img/aboutus/team-01.jpg"
                                                    className="img-fluid"
                                                    alt="Team-01"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Caterine</h4>
                                                <h6>Chief Executive Officer</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Caterine</h4>
                                                <h6>Chief Executive Officer</h6>
                                            </div>
                                            <p>
                                                Results-driven CEO, Ms. Caterine, fosters innovation and
                                                drives growth with proven success. Her strategic vision,
                                                collaborative leadership, and financial acumen consistently
                                                deliver excellence, value, and strong stakeholder
                                                relationships.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    src="assets/img/aboutus/team-02.jpg"
                                                    className="img-fluid"
                                                    alt="Team-02"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Anto</h4>
                                                <h6>Marketing Manager</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Anto</h4>
                                                <h6>Marketing Manager</h6>
                                            </div>
                                            <p>
                                                Mr. Anto, our Marketing Manager, passionately drives impactful
                                                marketing campaigns, expanding the reach of badminton. With
                                                expertise in brand management and digital marketing, he
                                                elevates our presence and engages our target audience.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    src="assets/img/aboutus/team-03.jpg"
                                                    className="img-fluid"
                                                    alt="Team-03"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Lucas Finn</h4>
                                                <h6>Team Leader</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Lucas Finn</h4>
                                                <h6>Team Leader</h6>
                                            </div>
                                            <p>
                                                Introducing Mr. Lucas Finn, our inspiring Team Leader in
                                                badminton. Lucas's commitment to excellence ensures seamless
                                                project execution and success in the sport.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    src="assets/img/aboutus/team-04.jpg"
                                                    className="img-fluid"
                                                    alt="Team-04"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Andrew</h4>
                                                <h6>Designer</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Andrew</h4>
                                                <h6>Designer</h6>
                                            </div>
                                            <p>
                                                Mr. Andrew is a skilled badminton designer known for
                                                transforming courts, creating striking packaging, designing
                                                stylish apparel, and developing captivating tournament
                                                branding.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    src="assets/img/aboutus/team-05.jpg"
                                                    className="img-fluid"
                                                    alt="Team-05"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Adrian</h4>
                                                <h6>Business Head</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Adrian</h4>
                                                <h6>Business Head</h6>
                                            </div>
                                            <p>
                                                Introducing Mr. Lucas Finn, our inspiring Team Leader in
                                                badminton. Lucas's commitment to excellence ensures seamless
                                                project execution and success in the sport.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    src="assets/img/aboutus/team-06.jpg"
                                                    className="img-fluid"
                                                    alt="Team-06"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Lucas Finn</h4>
                                                <h6>Team Leader</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Lucas Finn</h4>
                                                <h6>Team Leader</h6>
                                            </div>
                                            <p>
                                                Introducing Mr. Lucas Finn, our inspiring Team Leader in
                                                badminton. Lucas's commitment to excellence ensures seamless
                                                project execution and success in the sport.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    src="assets/img/aboutus/team-07.jpg"
                                                    className="img-fluid"
                                                    alt="Team-07"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Adrian</h4>
                                                <h6>Business Head</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Adrian</h4>
                                                <h6>Business Head</h6>
                                            </div>
                                            <p>
                                                Introducing Mr. Lucas Finn, our inspiring Team Leader in
                                                badminton. Lucas's commitment to excellence ensures seamless
                                                project execution and success in the sport.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="info text-center">
                                        <div className="wrap">
                                            <div className="prfile-pic">
                                                <img
                                                    src="assets/img/aboutus/team-08.jpg"
                                                    className="img-fluid"
                                                    alt="Team-08"
                                                />
                                            </div>
                                            <div className="short-info">
                                                <h4>Andrew</h4>
                                                <h6>Designer</h6>
                                            </div>
                                        </div>
                                        <div className="more">
                                            <div className="short-info">
                                                <h4>Andrew</h4>
                                                <h6>Designer</h6>
                                            </div>
                                            <p>
                                                Mr. Andrew is a skilled badminton designer known for
                                                transforming courts, creating striking packaging, designing
                                                stylish apparel, and developing captivating tournament
                                                branding.
                                            </p>
                                            <ul className="social-medias d-inline-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
                {/* <section className="section white-bg">
                    <div className="container">
                        <div className="section-heading">
                            <h2>
                                Our <span>Features</span>
                            </h2>
                            <p className="sub-title">
                                Discover your potential with our comprehensive training, expert
                                trainers, and advanced facilities.Join us to improve your athletic
                                career
                            </p>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex">
                                <div className="work-grid coaching-grid w-100">
                                    <div className="work-icon">
                                        <div className="work-icon-inner">
                                            <img
                                                src="assets/img/icons/coache-icon-01.svg"
                                                className="img-fluid"
                                                alt="Icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="work-content">
                                        <h3>Group Coaching</h3>
                                        <p>
                                            Accelerate your skills with tailored group coaching sessions for
                                            badminton players game.
                                        </p>
                                        <a href="javascript:void(0);">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                                <div className="work-grid coaching-grid w-100">
                                    <div className="work-icon">
                                        <div className="work-icon-inner">
                                            <img
                                                src="assets/img/icons/coache-icon-02.svg"
                                                className="img-fluid"
                                                alt="Icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="work-content">
                                        <h3>Private Coaching</h3>
                                        <p>
                                            Find private badminton coaches and academies for a personalized
                                            approach to skill enhancement.
                                        </p>
                                        <a href="javascript:void(0);">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                                <div className="work-grid coaching-grid w-100">
                                    <div className="work-icon">
                                        <div className="work-icon-inner">
                                            <img
                                                src="assets/img/icons/coache-icon-03.svg"
                                                className="img-fluid"
                                                alt="Icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="work-content">
                                        <h3>Equipment Store</h3>
                                        <p>
                                            Your one-stop shop for high-quality badminton equipment,
                                            enhancing your on-court performance.
                                        </p>
                                        <a href="javascript:void(0);">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                                <div className="work-grid coaching-grid w-100">
                                    <div className="work-icon">
                                        <div className="work-icon-inner">
                                            <img
                                                src="assets/img/icons/coache-icon-04.svg"
                                                className="img-fluid"
                                                alt="Icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="work-content">
                                        <h3>Innovative Lessons</h3>
                                        <p>
                                            Enhance your badminton skills with innovative lessons, combining
                                            modern techniques and training methods
                                        </p>
                                        <a href="javascript:void(0);">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                                <div className="work-grid coaching-grid w-100">
                                    <div className="work-icon">
                                        <div className="work-icon-inner">
                                            <img
                                                src="assets/img/icons/coache-icon-05.svg"
                                                className="img-fluid"
                                                alt="Icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="work-content">
                                        <h3>Badminton Community</h3>
                                        <p>
                                            Upraise your game with engaging lessons and a supportive
                                            community. Join us now and take your skills to new heights.
                                        </p>
                                        <a href="javascript:void(0);">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex">
                                <div className="work-grid coaching-grid w-100">
                                    <div className="work-icon">
                                        <div className="work-icon-inner">
                                            <img
                                                src="assets/img/icons/coache-icon-06.svg"
                                                className="img-fluid"
                                                alt="Icon"
                                            />
                                        </div>
                                    </div>
                                    <div className="work-content">
                                        <h3>Court Rental</h3>
                                        <p>
                                            Enjoy uninterrupted badminton sessions at DreamSports with our
                                            premium court rental services.
                                        </p>
                                        <a href="javascript:void(0);">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                
            </div>


            <Footer />
        </>
    )
}