

import { useSelector } from 'react-redux';
import { Link, useLocation, useRoutes } from 'react-router-dom';
import logoMain from './../assets/images/logo.png'

function Header() {

    const location = useLocation()

    console.log("key", location)

    const token = useSelector(state => state?.auth?.token)

  return (

    <>

    <nav className='mobile-header' >
        <img
            className="logo m-0 p-0 d-sm-block"
            src={logoMain}
            alt="dfdfd"
        />
    </nav>
    
    <nav className="navbar navbar-expand-lg header w-100 ">
        <div className="container-fluid">
        <a className="navbar-brand m-0 p-0 d-lg-none" href="#">
            <img src="./assets/images/logo.png" alt="" />
        </a>
        <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
        >
            <span className="navbar-toggler-icon rounded-1" />
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav mx-auto m-0 p-0 d-flex justify-content-between align-items-center container">
            <li className="nav-item">
                <Link to="/" >
                    <a className={`nav-link ${location.pathname == "/" ? "active" : ""}`} >
                        HOME
                    </a>
                </Link>
                
            </li>
            
            <li className="nav-item">
                <Link to="/grounds" >
                    <a className={`nav-link ${location.pathname == "/grounds" ? "active" : ""}`} >
                    PLAYGROUNDS
                    </a> 
                </Link>
            </li>
            <li className="nav-item">
                <Link className={`nav-link ${location.pathname == "/blogs" ? "active" : ""}`} to="/blogs">
                NEWS &amp; EVENTS
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/" className="nav-link d-none d-lg-block" href="#">
                <img
                    className="logo m-0 p-0 d-none d-sm-block"
                    src={logoMain}
                    alt="dfdfd"
                />
                </Link>
            </li>

            <li className="nav-item">
                <Link className={`nav-link ${location.pathname == "/about-us" ? "active" : ""}`} to="/about-us">
                ABOUT US
                </Link>
            </li>
            
            {token ?
            <li className="nav-item">
                <Link className={`nav-link ${location.pathname == "/my-bookings" ? "active" : ""}`} to="/my-bookings">
                    MY BOOKING
                </Link>
            </li>
            : null }
            {token ?
            <li className="nav-item">
                <Link className={`nav-link ${location.pathname == "/dashboard" ? "active" : ""}`} to="/dashboard">
                DASHBOARD
                </Link>
            </li>
            : null }
            {!token ?
            <li className="nav-item">
                <Link className={`nav-link ${location.pathname == "/login" ? "active" : ""}`} to="/login">
                SIGNIN
                </Link>
            </li>
            : null }
            {!token ?
            <li className="nav-item">
                <Link className={`nav-link ${location.pathname == "/signup" ? "active" : ""}`} to="/signup">
                SIGNUP
                </Link>
            </li>
            : null }
            </ul>
        </div>
        </div>
    </nav>
        
    </>
  );
}

export default Header;
