import { toast } from "react-toastify";
import { ResetAuth, SaveLogin, UpdateAuthChecked } from "../redux/authSlice";
import { store } from "../redux/store";
import APIS from "../apis";
import moment from "moment";
import _ from "lodash";

export function GetPriceRange(slots){

    if(!slots || slots?.length == 0){
        return [0,0]
    }

    // const lowestValueObject = slots.reduce((acc, obj) => {
    //     return obj.regularPrice < acc.regularPrice ? obj : acc;
    // });

    // const highestValueObject = slots.reduce((acc, obj) => {
    //     return obj.regularPrice > acc.regularPrice ? obj : acc;
    // });

    // return [lowestValueObject?.discountPrice || lowestValueObject?.regularPrice, highestValueObject?.discountPrice || highestValueObject?.regularPrice]

    const lowestValueObject = slots.reduce((acc, obj) => {
        return obj.regularPrice < acc.regularPrice ? obj : acc;
    });

    const highestValueObject = slots.reduce((acc, obj) => {
        return obj.regularPrice > acc.regularPrice ? obj : acc;
    });

    return [lowestValueObject?.discountedPrice || lowestValueObject?.regularPrice, highestValueObject?.discountedPrice || highestValueObject?.regularPrice]

}

export function GetLowestPrice(slots){

    if(!slots || slots?.length == 0){
        return [0,0]
    }

    const lowestValueObject = slots.reduce((acc, obj) => {
        return obj.regularPrice < acc.regularPrice ? obj : acc;
    });

    return lowestValueObject

}

// export const  BASE_URL = "http://localhost:3333"
export const  BASE_URL = "https://api.thesportsarena.xyz"



export function Notify(message, type = 'success'){
    toast[type](message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
}

export async function OnLoginSuccess(authData){

    store.dispatch(SaveLogin(authData))

    APIS.User.MyWishlist()

    localStorage.setItem('auth', JSON.stringify(authData))

}

export async function UpdateProfileData(data){

    let authData = _.cloneDeep(store.getState().auth)

    authData.user = data

    store.dispatch(SaveLogin(authData))

    localStorage.setItem('auth', JSON.stringify(authData))

}

export async function ChkAuth(){

    const authData = localStorage.getItem("auth")

    console.log(authData)

    if(authData){
        OnLoginSuccess(JSON.parse(authData))
    }

    store.dispatch(UpdateAuthChecked(true))

}

export async function Logout(navigate){
    localStorage.removeItem('auth')
    store.dispatch(ResetAuth())
    Notify("Signed Out")
    navigate("/")
}


export function ChkInWishList(_id){

    const { wishlist } = store.getState().common

    return wishlist?.find(ele => ele?.ground?._id == _id)

}

export const Slots = [
    { start: '00:00', end:"01:29", label: '12:00 AM - 01:30 AM' },
    { start: '01:30', end:"02:59", label: '01:30 AM - 03:00 AM' },
    { start: '06:00', end:"07:29", label: '06:00 AM - 07:30 AM' },
    { start: '07:30', end:"08:59", label: '07:30 AM - 09:00 AM' },
    { start: '09:00', end:"10:29", label: '09:00 AM - 10:30 AM' },
    { start: '10:30', end:"11:59", label: '10:30 AM - 12:00 PM' },
    { start: '12:00', end:"13:29", label: '12:00 PM - 01:30 PM' },
    { start: '13:30', end:"14:59", label: '01:30 PM - 03:00 PM' },
    { start: '14:59', end:"16:29", label: '03:00 PM - 04:30 PM' },
    { start: '16:30', end:"17:59", label: '04:30 PM - 06:00 PM' },
    { start: '18:00', end:"19:29", label: '06:00 PM - 07:30 PM' },
    { start: '19:30', end:"20:59", label: '07:30 PM - 09:00 PM' },
    { start: '21:00', end:"22:29", label: '09:00 PM - 10:30 PM' },
    { start: '22:30', end:"23:59", label: '10:30 PM - 11:59 PM' },
]

export const OneHourSlots = [
    { start: '00:00', end: '00:59', label: '12:00 AM - 01:00 AM' },
    { start: '01:00', end: '01:59', label: '01:00 AM - 02:00 AM' },
    { start: '02:00', end: '02:59', label: '02:00 AM - 03:00 AM' },
    { start: '06:00', end: '06:59', label: '06:00 AM - 07:00 AM' },
    { start: '07:00', end: '07:59', label: '07:00 AM - 08:00 AM' },
    { start: '08:00', end: '08:59', label: '08:00 AM - 09:00 AM' },
    { start: '09:00', end: '09:59', label: '09:00 AM - 10:00 AM' },
    { start: '10:00', end: '10:59', label: '10:00 AM - 11:00 AM' },
    { start: '11:00', end: '11:59', label: '11:00 AM - 12:00 PM' },
    { start: '12:00', end: '12:59', label: '12:00 PM - 01:00 PM' },
    { start: '13:00', end: '13:59', label: '01:00 PM - 02:00 PM' },
    { start: '14:00', end: '14:59', label: '02:00 PM - 03:00 PM' },
    { start: '15:00', end: '15:59', label: '03:00 PM - 04:00 PM' },
    { start: '16:00', end: '16:59', label: '04:00 PM - 05:00 PM' },
    { start: '17:00', end: '17:59', label: '05:00 PM - 06:00 PM' },
    { start: '18:00', end: '18:59', label: '06:00 PM - 07:00 PM' },
    { start: '19:00', end: '19:59', label: '07:00 PM - 08:00 PM' },
    { start: '20:00', end: '20:59', label: '08:00 PM - 09:00 PM' },
    { start: '21:00', end: '21:59', label: '09:00 PM - 10:00 PM' },
    { start: '22:00', end: '22:59', label: '10:00 PM - 11:00 PM' },
    { start: '23:00', end: '23:59', label: '11:00 PM - 12:00 AM' },
];


export const timeOptions = [
    { value: '00:01 AM', label: '12:00 AM' },
    { value: '01:30 AM', label: '01:30 AM' },
    { value: '03:00 AM', label: '03:00 AM' },
    { value: '06:00 AM', label: '06:00 AM' },
    { value: '07:30 AM', label: '07:30 AM' },
    { value: '09:00 AM', label: '09:00 AM' },
    { value: '10:30 AM', label: '10:30 AM' },
    { value: '12:00 PM', label: '12:00 PM' },
    { value: '13:30 PM', label: '01:30 PM' },
    { value: '15:00 PM', label: '03:00 PM' },
    { value: '16:30 PM', label: '04:30 PM' },
    { value: '18:00 PM', label: '06:00 PM' },
    { value: '19:30 PM', label: '07:30 PM' },
    { value: '21:00 PM', label: '09:00 PM' },
    { value: '22:30 PM', label: '10:30 PM' },
]

export const dateFormat = "YYYY-MM-DD"
export const  timeFormat = "HH:mm"


export function JoinDateNTime(dateStart, timeStart){
    return moment(`${moment(dateStart).format(dateFormat)} ${timeStart}`, `${dateFormat} ${timeFormat}`).toISOString()
}

export function CalculateTotalSlots(startDateISO, endDateISO) {

    console.log(startDateISO, endDateISO)

    const startDate = new Date(startDateISO);
    const endDate = new Date(endDateISO);

    // Define the start and end times for bookings
    const bookingStartTime = 6 * 60; // 6:00 AM in minutes
    const bookingEndTime = 3 * 60; // 3:00 AM the next day in minutes

    // Convert times to minutes for easier calculation
    const startTimeInMinutes = startDate.getHours() * 60 + startDate.getMinutes();
    const endTimeInMinutes = endDate.getHours() * 60 + endDate.getMinutes();

    // Total minutes for a slot (1 hour and 30 minutes)
    const slotTimeInMinutes = 90;

    // Calculate the total minutes between start and end time
    let totalMinutes = (endDate - startDate) / 60000; // Convert milliseconds to minutes

    // Adjust for the time between 3:00 AM and 6:00 AM
    if (startTimeInMinutes >= bookingEndTime && startTimeInMinutes < bookingStartTime) {
        totalMinutes -= Math.min(slotTimeInMinutes, startTimeInMinutes - bookingEndTime);
    }
    if (endTimeInMinutes > bookingEndTime && endTimeInMinutes <= bookingStartTime) {
        totalMinutes -= Math.min(slotTimeInMinutes, bookingStartTime - endTimeInMinutes);
    }
    
    // Calculate the total slots
    const totalSlots = Math.floor(totalMinutes / slotTimeInMinutes);

    return totalSlots;

}

export function CalculateExcludedSlots(startDateISO, endDateISO) {
    const startDate = new Date(startDateISO);
    const endDate = new Date(endDateISO);

    // Define the start and end times for bookings
    const bookingStartTime = 6 * 60; // 6:00 AM in minutes
    const bookingEndTime = 3 * 60; // 3:00 AM the next day in minutes

    // Convert times to minutes for easier calculation
    const startTimeInMinutes = startDate.getUTCHours() * 60 + startDate.getUTCMinutes();
    const endTimeInMinutes = endDate.getUTCHours() * 60 + endDate.getUTCMinutes();

    // Total minutes for a slot (1 hour and 30 minutes)
    const slotTimeInMinutes = 90;

    let excludedSlots = 0;

    // Exclude the time between 3:00 AM and 6:00 AM
    if (startTimeInMinutes < bookingEndTime) {
        excludedSlots += Math.ceil((bookingEndTime - startTimeInMinutes) / (slotTimeInMinutes));
    }
    if (endTimeInMinutes > bookingStartTime) {
        excludedSlots += Math.ceil((endTimeInMinutes - bookingStartTime) / (slotTimeInMinutes));
    }

    return excludedSlots;
}

export function CalculateWorkDays(startDate, endDate) {
    const startHour = 3; // 03:00 AM
    const endHour = 6; // 06:00 AM
  
    let workDays = 0;
  
    let start = new Date(startDate);
    const end = new Date(endDate);
  
    while (start <= end) {
      const currentHour = start.getUTCHours();
      if (currentHour >= startHour && currentHour < endHour) {
        workDays++;
      }
      start.setDate(start.getDate() + 1); // Move to the next day
    }
  
    return workDays;
  }

  export function GetWeekDates(inputDate) {
    const date = moment(inputDate);
    const monday = date.startOf('isoWeek');
    const weekDates = [];
  
    for (let i = 0; i < 7; i++) {
      const currentDate = moment(monday).add(i, 'days');
      weekDates.push(currentDate);
    }
  
    return weekDates;
  }

  export function GetMiddleTime(time1, time2) {
    const m1 = moment(time1);
    const m2 = moment(time2);
  
    // Calculate the average of the two times
    const middleTime = moment((m1.valueOf() + m2.valueOf()) / 2);
  
    return middleTime.toISOString() // Format as desired
  }

  export const CarouselResponsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const CarouselResponsiveTest = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export const testimonials = [
    {
        text:"I’ve been playing both football and cricket at The Sports Arena, and I’m impressed with the quality of the facilities. The staff is friendly, and the environment is perfect for sports lovers. A great place to enjoy and improve your game!",
        user:"Abu Shayem, Doreen Group",
    },
    {
        text:"I've played cricket at many places, but The Sports Arena stands out. The indoor pitch is perfect, and the atmosphere is great for competitive and friendly matches alike. My go-to spot!",
        user:"Taukir Ahmed, Incredible Pvt. Ltd",
    },
    {
        text:"The football experience at The Sports Arena is unparalleled. Great facilities, easy booking, and a fantastic indoor field. Perfect for weekend matches with friends!",
        user:"Syed Navid Anjum, Midland Bank",
    }
]

