import Footer from "../component/Footer";
import Header from "../component/Header";

import DashLogo from "./../assets-dream/img/icons/dashboard-icon.svg"
import MyBookingLogo from "./../assets-dream/img/icons/booking-icon.svg"
import ProfileLogo from "./../assets-dream/img/icons/profile-icon.svg"
import ChatLogo from "./../assets-dream/img/icons/chat-icon.svg"
import StateLogo from "./../assets-dream/img/icons/statistics-01.svg"
import { useEffect, useState } from "react";
import APIS from "../apis";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, Logout } from "../uti/uti";
import moment from "moment";
import { useSelector } from "react-redux";
import DashboardHead from "../component/DashboardHead";
import SingleGround from "../component/SingleGround";

export default function MyFavourites() {

  const wishlist = useSelector(state => state?.common?.wishlist)

  const navigate = useNavigate()

  const token = useSelector(state => state?.auth?.token)

  console.log("wishlist", wishlist)

  return (
    <>
      <Header />
      <div className="" style={{marginTop:80}} >
        
        <DashboardHead 
          screenName="my-favourites"
        />
        
        <div className="content">
          <div className="container">
            
            <div className="row">
              <div className="col-xl-12 col-lg-12 d-flex">
                <div className="card dashboard-card flex-fill">
                  <div className="card-header card-header-info">
                    <div className="card-header-inner">
                      <h4>My Favourites</h4>
                      <p>Bookmarked grounds are avaliable here</p>
                    </div>
                    
                  </div>

                </div>
              </div>
              {wishlist?.map((ele, index) => 
                <SingleGround 
                  ele={ele?.ground}
                  index={index}
                  key={index}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  )
}