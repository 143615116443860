import { useEffect, useState } from "react";
import APIS from "../apis";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import { BASE_URL, CalculateExcludedSlots, CalculateTotalSlots, CalculateWorkDays, ChkInWishList, JoinDateNTime, Notify, timeOptions } from "../uti/uti";
import SingleGround from "../component/SingleGround";

import DatePicker from 'react-date-picker';
import Select from 'react-select';
import moment from "moment";
import { useSelector } from "react-redux";


export default function Grounds() {

    const [data, Setdata] = useState([])
    const [searchText, SetsearchText] = useState("")

    const wishlist = useSelector(state => state?.common?.wishlist)

    let dateFormat = "YYYY-MM-DD"
    let timeFormat = "hh:mm A"


    const [dateStart, onChangedateStart] = useState("");
    const [dateEnd, onChangedateEnd] = useState("");

    const [timeStart, settimeStart] = useState("");

    const [timeEnd, settimeEnd] = useState("");
    const [loading, setloading] = useState(false);

    useEffect(() => {
        LoadGrounds()
    }, [searchText])

    const LoadGrounds = async (filter) => {
        setloading(true)
        const res = await APIS.Ground.Grounds(searchText, filter)
        setloading(false)

        if(res){
            Setdata(res)
        }
    }

    useEffect(() => {

        if(dateStart && dateEnd && timeEnd && timeStart){
            let startDateTime = JoinDateNTime(dateStart, timeStart)
            let endDateTime = JoinDateNTime(dateEnd, timeEnd)
    
            console.log(moment(endDateTime).diff(moment(startDateTime), 'minute'))
    
            if(moment(endDateTime).diff(moment(startDateTime), 'minute') > 1){
    
                LoadGrounds({
                    searchDateStart:startDateTime,
                    searchDateEnd:endDateTime,
                })
    
            }else{
                Notify("Please select future end date or time", "error")
            }
        }

        


    }, [dateStart, dateEnd, timeEnd, timeStart])

    return (
        <>
        <Header />
        <div className="content listing-list-page"  >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="sortby-section mt-5">
                            <div className="sorting-info">
                                <div className="row d-flex align-items-center">
                                    <div className="col-xl-4 col-lg-3 col-sm-12 col-12">
                                        <div className="count-search">
                                            <p>
                                                <span>{data?.length}</span> venues are listed
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12" style={{display:'none'}} >
                        <div className="grounds-filter" >
                            <section className="booking-form">
                                <h3 className="border-bottom">Filter Grounds</h3>
                                <div className="form-group mb-4">
                                    <div className="group-img">
                                        <input
                                            value={searchText}
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Ground"
                                            onChange={e => SetsearchText(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="date" className="form-label">
                                            Date From
                                        </label>
                                        <div className="filterDate" >
                                            <DatePicker
                                                onChange={onChangedateStart}
                                                value={dateStart}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="start-time" className="form-label">
                                            Start Time
                                        </label>
                                        <Select
                                            placeholder="Select Date"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={timeStart}
                                            name="color"
                                            options={timeOptions}
                                            onChange={e => settimeStart(e?.value)}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="date" className="form-label">
                                            Date To
                                        </label>
                                        <div className="filterDate" >
                                            <DatePicker
                                                onChange={onChangedateEnd}
                                                value={dateEnd}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="end-time" className="form-label">
                                            End Time
                                        </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            defaultValue={timeEnd}
                                            name="color"
                                            options={timeOptions}
                                            onChange={e => settimeEnd(e?.value)}
                                        />
                                    </div>

                                    {/* <h1>Selected Slots {CalculateTotalSlots(moment(`${moment(dateStart).format(dateFormat)} ${timeStart}`, `${dateFormat} ${timeFormat}`).toLocaleString(), moment(`${moment(dateEnd).format(dateFormat)} ${timeEnd}`, `${dateFormat} ${timeFormat}`).toLocaleString())}</h1>

                                    <h1>Selected Slots {CalculateExcludedSlots(moment(`${moment(dateStart).format(dateFormat)} ${timeStart}`, `${dateFormat} ${timeFormat}`).toLocaleString(), moment(`${moment(dateEnd).format(dateFormat)} ${timeEnd}`, `${dateFormat} ${timeFormat}`).toLocaleString())}</h1>
                                    <h1>Selected Time {CalculateWorkDays(moment(`${moment(dateStart).format(dateFormat)} ${timeStart}`, `${dateFormat} ${timeFormat}`).toLocaleString(), moment(`${moment(dateEnd).format(dateFormat)} ${timeEnd}`, `${dateFormat} ${timeFormat}`).toLocaleString())}</h1> */}
                                </form>
                            </section>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                        <div className="row justify-content-center">
                            {data.map((ele, index) => 
                                <SingleGround 
                                    ele={ele}
                                    key={index}
                                    index={index}
                                />
                            )}
                            
                            <div className="col-12 text-center">
                                <div className="more-details">
                                    {loading ? "Loading..." : "No More Grounds"}
                                    {/* <a href="#" className="btn btn-load">
                                        Load More Grounds{" "}
                                        <img
                                            src="assets/img/icons/u_plus-square.svg"
                                            className="ms-2"
                                            alt="Icon"
                                        />
                                    </a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <Footer />
        </>
    )
}