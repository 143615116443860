import axios from "axios"
import { store } from "../redux/store"
import { BASE_URL, Notify } from "../uti/uti"

export async function CallAuthPost(url, payload){

    const token = store.getState().auth.token

    try{

        const res = await axios.post(BASE_URL+url, payload, {
            headers:{
                Authorization:`Bearer ${token}`
            }
        })

        if(res?.data.success){
            return res?.data
        }

        Notify(res?.data?.message, "error")
        
        return false

    }catch(e){

        console.log(e)

        Notify("Something went wrong", "error")

        return false
    }
    
}


export async function CallAuthGet(url, payload){

    const token = store.getState().auth.token

    try{

        const res = await axios.get(BASE_URL+url, {
            headers:{
                Authorization:`Bearer ${token}`
            }
        })

        if(res?.data.success){
            return res?.data
        }

        Notify(res?.data?.message, "error")
        
        return false

    }catch(e){

        Notify("Something went wrong", "error")

        return false
    }
    
}



export async function CallAuthDelete(url){

    const token = store.getState().auth.token

    try{

        const res = await axios.delete(BASE_URL+url, {
            headers:{
                Authorization:`Bearer ${token}`
            }
        })

        if(res?.data.success){
            return res?.data
        }

        Notify(res?.data?.message, "error")
        
        return false

    }catch(e){

        Notify("Something went wrong", "error")

        return false
    }
    
}

