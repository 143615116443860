import { useEffect, useState } from "react";
import APIS from "../apis";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link } from "react-router-dom";
import { BASE_URL, ChkInWishList } from "../uti/uti";
import ImagePlaceholder from '../assets/images/placeholder-image.jpg'
import moment from "moment";


export default function Blogs() {

    const [data, Setdata] = useState([])
    const [searchText, SetsearchText] = useState("")

    useEffect(() => {
        LoadData()
    }, [searchText])

    const LoadData = async () => {
        const res = await APIS.Blog.Blogs(searchText)
        if (res) {
            Setdata(res)
        }
    }

    return (
        <>
            <Header />
            <div className="content blog-grid" style={{ marginTop: 100 }} >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4 blog-sidebar theiaStickySidebar">
                            <div className="card">
                                <h4>Search</h4>
                                <form className="">
                                    <div className="blog-search">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            placeholder="Enter Keyword"
                                            onChange={e => SetsearchText(e?.target?.value)}
                                        />
                                        <i className="feather-search" />
                                    </div>
                                </form>
                            </div>
                            <div className="card">
                                <h4>Latest Posts</h4>
                                <ul className="latest-posts">
                                    {data?.map(ele =>
                                        <li>
                                            <div className="post-thumb">
                                                <Link to={"/blogs/" + ele?.slug}>
                                                    <img
                                                        className="img-fluid"
                                                        src={ele.thumbImage ? BASE_URL + '/' + ele.thumbImage : ImagePlaceholder}
                                                        alt="Post"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="post-info">
                                                <h6 style={{ marginTop: 7, marginBottom: 3 }} >
                                                    <Link to={`/blogs/${ele?.slug}`}>
                                                        {ele?.title}
                                                    </Link>
                                                </h6>
                                                <p>{moment(ele?.createdAt).fromNow()}</p>

                                            </div>
                                        </li>
                                    )}
                                    {/* <li>
                        <div className="post-thumb">
                            <a href="blog-details.html">
                            <img
                                className="img-fluid"
                                src="assets/img/blog/latestpost-02.jpg"
                                alt="Post"
                            />
                            </a>
                        </div>
                        <div className="post-info">
                            <p>Kim Forrest</p>
                            <h6>
                            <a href="blog-details.html">
                                23 Super-Chic Blazer Dresses for Your Home Game
                            </a>
                            </h6>
                        </div>
                        </li>
                        <li>
                        <div className="post-thumb">
                            <a href="blog-details.html">
                            <img
                                className="img-fluid"
                                src="assets/img/blog/latestpost-03.jpg"
                                alt="Post"
                            />
                            </a>
                        </div>
                        <div className="post-info">
                            <p>Jessica Estrada</p>
                            <h6>
                            <a href="blog-details.html">
                                The Prettiest Floral Bats for a Rage Look
                            </a>
                            </h6>
                        </div>
                        </li>
                        <li>
                        <div className="post-thumb">
                            <a href="blog-details.html">
                            <img
                                className="img-fluid"
                                src="assets/img/blog/latestpost-04.jpg"
                                alt="Post"
                            />
                            </a>
                        </div>
                        <div className="post-info">
                            <p>Naoimh</p>
                            <h6>
                            <a href="blog-details.html">
                                31 Beautiful Courts Around the World
                            </a>
                            </h6>
                        </div>
                        </li> */}
                                </ul>
                            </div>
                            {/* <div className="card">
                                <h4>Categories</h4>
                                <ul className="categories">
                                    <li>
                                        <h6>
                                            <a href="javascript:void(0);">
                                                {" "}
                                                Rules in Game <span>(100)</span>
                                            </a>
                                        </h6>
                                    </li>
                                    <li>
                                        <h6>
                                            <a href="javascript:void(0);">
                                                {" "}
                                                Badminton <span>(10)</span>
                                            </a>
                                        </h6>
                                    </li>
                                    <li>
                                        <h6>
                                            <a href="javascript:void(0);">
                                                {" "}
                                                Bats <span>(20)</span>
                                            </a>
                                        </h6>
                                    </li>
                                    <li>
                                        <h6>
                                            <a href="javascript:void(0);">
                                                {" "}
                                                New Game <span>(45)</span>
                                            </a>
                                        </h6>
                                    </li>
                                    <li>
                                        <h6>
                                            <a href="javascript:void(0);">
                                                {" "}
                                                Event <span>(25)</span>
                                            </a>
                                        </h6>
                                    </li>
                                    <li>
                                        <h6>
                                            <a href="javascript:void(0);">
                                                {" "}
                                                Rackets <span>(15)</span>
                                            </a>
                                        </h6>
                                    </li>
                                    <li>
                                        <h6>
                                            <a href="javascript:void(0);">
                                                {" "}
                                                New Courts <span>(121)</span>
                                            </a>
                                        </h6>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="card tags-card">
                                <h4>Tags</h4>
                                <ul className="tags clearfix">
                                    <li>
                                        <a href="#" className="tag">
                                            Rackets
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="tag">
                                            New Game
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="tag">
                                            Dresses
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            {data?.map(ele =>
                                <div className="featured-venues-item">

                                    <div className="listing-item">
                                        <div className="listing-img">
                                            <Link to={"/blogs/" + ele?.slug}>
                                                <img
                                                    // src={ImagePlaceholder}
                                                    src={ele.thumbImage ? BASE_URL + '/' + ele.thumbImage : ImagePlaceholder}

                                                    className="img-fluid"
                                                    alt="Venue"
                                                />
                                            </Link>
                                            {/* <div className="fav-item-venues news-sports">
                                    <span className="tag tag-blue">Badminton</span>
                                    <div className="list-reviews coche-star">
                                    <a href="javascript:void(0)" className="fav-icon">
                                        <i className="feather-heart" />
                                    </a>
                                    </div>
                                </div> */}
                                        </div>
                                        <div className="listing-content news-content">
                                            {/* <div className="listing-venue-owner">
                                    <div className="navigation">
                                    <a href="javascript:void(0)">
                                        <img src="assets/img/profiles/avatar-01.jpg" alt="User" />
                                        Orlando Waters
                                    </a>
                                    <span>
                                        <i className="feather-calendar" />
                                        15 May 2023
                                    </span>
                                    </div>
                                </div> */}
                                            <h3 className="listing-title">
                                                <Link to={"/blogs/" + ele?.slug}>
                                                    {ele?.title}{" "}
                                                </Link>
                                            </h3>
                                            {/* <p>
                                                {ele?.body}
                                            </p> */}
                                            <div className="listing-button read-new">
                                                <ul className="nav">
                                                    {/* <li>
                                                        <a href="javascript:void(0);">
                                                        <i className="feather-heart" />
                                                        45
                                                        </a>
                                                    </li> */}
                                                    {/* <li>
                                                        <a href="javascript:void(0);">
                                                            <i className="feather-message-square" />
                                                            40
                                                        </a>
                                                    </li> */}
                                                </ul>
                                                <span>
                                                    <img src="assets/img/icons/clock.svg" alt="" />
                                                    10 Min To Read
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* <div className="featured-venues-item">
                    <div className="listing-item">
                        <div className="listing-img">
                        <a href="blog-details.html">
                            <img
                            src="assets/img/blog/blog-02.jpg"
                            className="img-fluid"
                            alt="Venue"
                            />
                        </a>
                        <div className="fav-item-venues news-sports">
                            <span className="tag tag-blue">Sports Activites</span>
                            <div className="list-reviews coche-star">
                            <a href="javascript:void(0)" className="fav-icon">
                                <i className="feather-heart" />
                            </a>
                            </div>
                        </div>
                        </div>
                        <div className="listing-content news-content">
                        <div className="listing-venue-owner">
                            <div className="navigation">
                            <a href="javascript:void(0)">
                                <img src="assets/img/profiles/avatar-06.jpg" alt="User" />
                                Claire Nichols
                            </a>
                            <span>
                                <i className="feather-calendar" />
                                16 Jun 2023
                            </span>
                            </div>
                        </div>
                        <h3 className="listing-title">
                            <a href="blog-details.html">
                            Unleashing Your Badminton Potential: Tips for Skill
                            Development
                            </a>
                        </h3>
                        <p>
                            Unlock your badminton potential and elevate your skills with our
                            expert tips for effective skill development on the court.
                        </p>
                        <div className="listing-button read-new">
                            <ul className="nav">
                            <li>
                                <a href="javascript:void(0);">
                                <i className="feather-heart" />
                                32
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                <i className="feather-message-square" />
                                21
                                </a>
                            </li>
                            </ul>
                            <span>
                            <img src="assets/img/icons/clock.svg" alt="Icon" />5 Min To
                            Read
                            </span>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="featured-venues-item">
                    <div className="listing-item">
                        <div className="listing-img">
                        <a href="blog-details.html">
                            <img
                            src="assets/img/blog/blog-03.jpg"
                            className="img-fluid"
                            alt="Venue"
                            />
                        </a>
                        <div className="fav-item-venues news-sports">
                            <span className="tag tag-blue">Rules of Game</span>
                            <div className="list-reviews coche-star">
                            <a href="javascript:void(0)" className="fav-icon">
                                <i className="feather-heart" />
                            </a>
                            </div>
                        </div>
                        </div>
                        <div className="listing-content news-content">
                        <div className="listing-venue-owner">
                            <div className="navigation">
                            <a href="javascript:void(0)">
                                <img src="assets/img/profiles/user-04.jpg" alt="User" />
                                Joanna Le
                            </a>
                            <span>
                                <i className="feather-calendar" />
                                17 May 2023
                            </span>
                            </div>
                        </div>
                        <h3 className="listing-title">
                            <a href="blog-details.html">
                            The Art of Footwork: Enhancing Agility in Badminton
                            </a>
                        </h3>
                        <p>
                            Master the art of footwork and elevate your agility in badminton
                            with our proven techniques and training methods.
                        </p>
                        <div className="listing-button read-new">
                            <ul className="nav">
                            <li>
                                <a href="javascript:void(0);">
                                <i className="feather-heart" />
                                55
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                <i className="feather-message-square" />
                                32
                                </a>
                            </li>
                            </ul>
                            <span>
                            <img src="assets/img/icons/clock.svg" alt="Icon" />4 Min To
                            Read
                            </span>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="blog-pagination">
                    <nav>
                        <ul className="pagination justify-content-center pagination-center">
                        <li className="page-item previtem">
                            <a className="page-link" href="javascript:void(0);">
                            <i className="feather-chevrons-left" />
                            </a>
                        </li>
                        <li className="page-item previtem">
                            <a className="page-link" href="javascript:void(0);">
                            <i className="feather-chevron-left" />
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link active" href="javascript:void(0);">
                            1
                            </a>
                        </li>
                        <li className="page-item active">
                            <a className="page-link" href="javascript:void(0);">
                            2
                            </a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="javascript:void(0);">
                            3
                            </a>
                        </li>
                        <li className="page-item nextlink">
                            <a className="page-link" href="javascript:void(0);">
                            {" "}
                            <i className="feather-chevron-right" />
                            </a>
                        </li>
                        <li className="page-item nextlink">
                            <a className="page-link" href="javascript:void(0);">
                            {" "}
                            <i className="feather-chevrons-right" />
                            </a>
                        </li>
                        </ul>
                    </nav>
                    </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}