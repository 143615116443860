import { useEffect, useState } from "react";
import Footer from "../component/Footer";
import Header from "../component/Header";
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIS from "../apis";
import { BASE_URL, GetLowestPrice, GetPriceRange, JoinDateNTime, Notify } from "../uti/uti";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import TimeSlotCalendar from "../component/TimeSlotCalender";
import _ from "lodash";
import MakePaymentModal from "../component/MakePaymentModal";
import ConfirmBooking from "../component/ConfirmBooking";

export default function BookGround() {

    const token = useSelector(state => state?.auth?.token)


    const navigate = useNavigate()

    let dateFormat = "YYYY-MM-DD"
    let timeFormat = "hh:mm A"


    const [startDate, SetstartDate] = useState("");
    const [endDate, SetendDate] = useState("");
    const [showConfirmBooking, SetshowConfirmBooking] = useState(false);

    // const onChangedateStart = (value) => {
    //     console.log(value)
    //     console.log(moment(`${moment(timeStart).format(dateFormat)} ${moment(timeStart).format(timeFormat)}`, `${dateFormat} ${timeFormat}`).toISOString())
    //     settimeStart(oldValue => moment(`${moment(oldValue).format(dateFormat)} ${moment(oldValue).format(timeFormat)}`, `${dateFormat} ${timeFormat}`).toISOString())
    // }

    const [timeStart, settimeStart] = useState("");

    const [timeEnd, settimeEnd] = useState("");
    
    const [loading, setloading] = useState(false);

    const [groundData, SetGroundData] = useState({
        gallery : [],
        slots:[]
    })

    const [selectedSlots, SetselectedSlots] = useState([])
    
    const params = useParams()

    const OnPressSlot = (date, slotStartTime, slotEndTime, slotNumber) => {

        // let allSele = _.cloneDeep(selectedSlots)
        let allSele = []

        const index = allSele.findIndex(ele => ele.startDate == JoinDateNTime(date, slotStartTime) && ele.endDate == JoinDateNTime(date, slotEndTime))

        if(index > -1){

            allSele.splice(index, 1)
            
        }else{

            const weekday = moment(date).format("dddd").toLowerCase()

            console.log(slotNumber, weekday)

            const selectedSlotData = groundData?.slots?.find(ele => ele.slotNumber == slotNumber && ele.weekDay == weekday)


            allSele.push({
                date, 
                slotStartTime, 
                slotEndTime,
                startDate:JoinDateNTime(date, slotStartTime),
                endDate:JoinDateNTime(date, slotEndTime),
                slotNumber:slotNumber,
                weekday:weekday,
                selectedSlotData:selectedSlotData
            })
        }

        console.log(allSele)

        SetselectedSlots(allSele)

        // if(!startDate || (startDate && endDate)){
        //     SetstartDate(JoinDateNTime(date, slotStartTime))
        //     SetendDate("")
        // }else{
        //     SetendDate(JoinDateNTime(date, slotEndTime))
        // }
    }

    useEffect(() => {
        LoadDetails()
    }, [])

    const LoadDetails = async () => {
        const res = await APIS.Ground.GroundBySlug(params?.slug)
        console.log(res)
        if(res){
            SetGroundData(res)
        }
    }

    const Book = async () => {

        if(!token){
            Notify("Please Signin or Sign up to book", "error")
            return null
        }

        if(selectedSlots?.length == 0){
            Notify("Please Select Start Time", "error")
            return null
        }

        // if(!startDate){
        //     Notify("Please Select Start Time", "error")
        //     return null
        // }

        // if(!endDate){
        //     Notify("Please Select End Time", "error")
        //     return null
        // }

        setloading(true)

        // for (let index = 0; index < selectedSlots.length; index++) {

        //     const payload = {
        //         ground:groundData?._id,
        //         startDate:selectedSlots[index].startDate,
        //         endDate:selectedSlots[index].endDate,
        //         selectedSlotData:selectedSlots[index].selectedSlotData
        //     }

        //     console.log(payload, startDate,timeStart, timeEnd )
        //     // return false;

        //     const res = await APIS.Ground.BookGround(payload)
            
        // }

            const payload = {
                ground:groundData?._id,
                startDate:selectedSlots[0].startDate,
                endDate:selectedSlots[0].endDate,
                selectedSlotData:selectedSlots[0].selectedSlotData
            }

            console.log(payload, startDate,timeStart, timeEnd )
            // return false;

            const res = await APIS.Ground.BookGround(payload)

            if(res){

                if(groundData?.reqAdvancedAmount){

                    const paymentRes = await APIS.Ground.MakeAdvancePayment(res?.savedData)

                    if(paymentRes?.bkashPaymentData?.bkashURL){
                        window.location.replace(paymentRes?.bkashPaymentData?.bkashURL)
                    }


                }else{
                    navigate('/my-bookings')
                }

                setloading(false)


            }

        

        setloading(false)


        // console.log(res )


        // if(res){
        //     navigate('/dashboard')
        // }

        // navigate('/dashboard')

    }

    const SubTotal = () => {

        let total = 0

        selectedSlots.map(ele => {

            total = total + (ele?.selectedSlotData?.discountedPrice || ele?.selectedSlotData?.regularPrice)

        })

        return total
    }


    return (
        <>
            <Header />
            <div style={{marginTop:50}}>
                {/* <section className="booking-steps py-30">
                    <div className="container">
                        <ul className="d-lg-flex justify-content-center align-items-center">
                            <li className="active">
                                <h5>
                                    <a href="cage-details.html">
                                        <span>1</span>Book a Court
                                    </a>
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    <a href="cage-order-confirm.html">
                                        <span>2</span>Order Confirmation
                                    </a>
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    <a href="cage-personalinfo.html">
                                        <span>3</span>Personal Information
                                    </a>
                                </h5>
                            </li>
                            <li>
                                <h5>
                                    <a href="cage-checkout.html">
                                        <span>4</span>Payment
                                    </a>
                                </h5>
                            </li>
                        </ul>
                    </div>
                </section> */}
                <div className="content book-cage">
                    <div className="container">
                        <section className="card mb-40">
                            <div className="text-center mb-40">
                                <h3 className="mb-1">Book A Court</h3>
                                <p className="sub-title mb-0">
                                    Hassle-free ground bookings and state-of-the-art facilities.
                                </p>
                            </div>
                            <div className="master-academy dull-whitesmoke-bg card">
                                <div className="row d-flex align-items-center justify-content-center">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <div className="d-sm-flex justify-content-start align-items-center">
                                            <Link 
                                                to={`/grounds/${groundData?.slug}`}
                                            >
                                                <img
                                                    className="corner-radius-10"
                                                    src={groundData?.gallery[0] ? BASE_URL +'/' + groundData?.gallery[0]  : "assets/img/master-academy.png"}
                                                    alt="Venue"
                                                    style={{maxWidth:200, borderRadius:10}}
                                                />
                                            </Link>
                                            <div className="info">
                                                <div className="d-flex justify-content-start align-items-center mb-3">
                                                    <span className="text-white dark-yellow-bg color-white me-2 d-flex justify-content-center align-items-center">
                                                        {/* 4.5 */}
                                                        {(groundData?.totalReviews || 0)/(groundData?.totalStar || 1)}
                                                    </span>
                                                    <span>{groundData?.totalReviews} Reviews</span>
                                                </div>
                                                <h3 className="mb-2">{groundData?.name}</h3>
                                                <p>
                                                    {groundData?.bookingIns}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <ul className="d-sm-flex align-items-center justify-content-evenly">
                                            <li>
                                                <h3 className="d-inline-block">
                                                    {/* ৳{groundData?.slotPrice} */}
                                                    {/* ৳{GetPriceRange(groundData?.slots)[0]}-{GetPriceRange(groundData?.slots)[1]} */}

                                                    <>
                                                        <span style={{color:'#000'}} >Starts From</span> ৳{GetLowestPrice(groundData?.slots)?.discountedPrice} <span style={{textDecoration:'line-through', color:'#000'}} >{GetLowestPrice(groundData?.slots)?.regularPrice}</span>
                                                    </>

                                                </h3>
                                                {/* <span>/slot</span> */}
                                                {/* <p>up to 1 guests</p> */}
                                            </li>
                                            {/* <li>
                                                <span>
                                                    <i className="feather-plus" />
                                                </span>
                                            </li>
                                            <li>
                                                <h3 className="d-inline-block">$5</h3>
                                                <span>/hr</span>
                                                <p>
                                                    each additional guest <br />
                                                    up to 4 guests max
                                                </p>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="row">
                            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <section className="card booking-form">
                                    <h3 className="border-bottom">Booking Form</h3>
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="date" className="form-label">
                                                From
                                            </label>
                                            <div>
                                                <DatePicker
                                                    onChange={onChangedateStart}
                                                    value={dateStart}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="start-time" className="form-label">
                                                Start Time
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={timeStart}
                                                name="color"
                                                options={timeOptions}
                                                onChange={e => settimeStart(e?.value)}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="end-time" className="form-label">
                                                End Time
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={timeEnd}
                                                name="color"
                                                options={timeOptions}
                                                onChange={e => settimeEnd(e?.value)}
                                            />
                                        </div>
                                    </form>
                                </section>
                            </div> */}
                            {groundData?._id ?
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <TimeSlotCalendar 
                                    groundData={groundData}
                                    startDate={startDate}
                                    endDate={endDate}
                                    OnPressSlot={OnPressSlot}
                                    selectedSlots={selectedSlots}
                                />
                            </div>
                            : null }
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                <aside className="card booking-details">
                                    <h3 className="border-bottom">Booking Details</h3>
                                    <ul>
                                        <li>
                                            <i className="fa-regular fa-building me-2" />
                                            {groundData?.name}<span className="x-circle" />
                                        </li>
                                        {/* <li>
                                            <i className="feather-calendar me-2" />
                                            {startDate ? moment(startDate).format("DD, MMMM YYYY - hh:mm A") : "Select Start Date"}
                                        </li>
                                        <li>
                                            <i className="feather-calendar me-2" />
                                            {endDate ? moment(endDate).format("DD, MMMM YYYY - hh:mm A") : "Select End Date"}
                                        </li> */}
                                        <li>
                                            <i className="feather-airplay me-2" />
                                            Subtotal : {SubTotal()}
                                        </li>
                                    </ul>
                                    <div className="d-grid btn-block">
                                        <button 
                                            type="button" 
                                            className="btn btn-primary"
                                            onClick={() => {
                                                if(!token){
                                                    Notify("Please Signin or Sign up to book", "error")
                                                    return null
                                                }
                                        
                                                if(selectedSlots?.length == 0){
                                                    Notify("Please Select Slot", "error")
                                                    return null
                                                }
                                                SetshowConfirmBooking(true)
                                            }}
                                            disabled={loading}
                                        >
                                            {loading ? "Booking..." : "BOOK THIS GROUND"}
                                            <i className="feather-arrow-right-circle ms-1" />
                                        </button>
                                    </div>
                                </aside>
                            </div>
                        </div>
                        {/* <div className="text-center btn-row">
                            <Button
                                className="btn btn-secondary btn-icon"
                                onClick={Book}
                                disabled={loading}
                            
                            >
                                {loading ? "Booking..." : "Book"} <i className="feather-arrow-right-circle ms-1" />
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>

            <Footer />

            <ConfirmBooking 
                groundData={groundData}
                OnConfirm={Book}
                Close={() => SetshowConfirmBooking(false)}
                show={showConfirmBooking}
                loading={loading}
            />

            {/* <MakePaymentModal 
                groundData={groundData}
            /> */}

        </>
    )
}