import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ConfirmBooking = ({groundData, OnConfirm, Close, show, loading}) => {

  return (
    <Modal show={show ? true : false} onHide={Close} >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Booking</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {groundData?.reqAdvancedAmount ?
          <p>You need to pay <b>৳{groundData?.reqAdvancedAmount}</b> BDT as advance to confirm booking. <b>Please inform us at least 48 hours in advance if you need to cancel your slot.</b></p>
        :
          <p></p>
        }

        <div style={{display:'flex', alignItems:'center'}} >

          <i className="feather-check-circle" style={{fontSize:16, marginRight:20, color:'#000'}} />

          <img 
            src={require("./../assets/images/bkash-logo.png")} 
            style={{
              height:40
            }}
          />
        </div>

      </Modal.Body>

      <Modal.Footer>
        <Button 
          variant="secondary"
          onClick={Close}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button 
          variant="primary" 
          onClick={OnConfirm} 
          disabled={loading}
        >
          {loading ? "Booking..." : "Confirm and Pay"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmBooking;