import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const CounterInView = ({end}) => {
  const [hasAnimated, setHasAnimated] = useState(false);

  const onVisibilityChange = (isVisible) => {
    if (isVisible && !hasAnimated) {
      setHasAnimated(true);
    }
  };

  return (
    <VisibilitySensor partialVisibility offset={{ bottom: 200 }} onChange={onVisibilityChange}>
      {({ isVisible }) => (
        <span>
          {hasAnimated ? <CountUp end={end} /> : isVisible ? 50 : 0 /* Show 0 until animation starts */}
        </span>
      )}
    </VisibilitySensor>
  );
};

export default CounterInView;