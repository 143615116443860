import { useState } from "react"
import { useSelector } from "react-redux"
import { Link, redirect, useNavigate } from "react-router-dom"
import APIS from "../apis"
import Footer from "../component/Footer"
import Header from "../component/Header"
import { Notify, OnLoginSuccess } from "../uti/uti"


export default function Login() {

    const navigate = useNavigate()

    const token = useSelector(state => state?.auth?.token)

    const [submitData, SetsubmitData] = useState({
        phone:"",
        password:"",
        // phone:'+8801940084384',
        // password:'Server123#'
    })

    const [showPass, SetshowPass] = useState(false)

    const Submit  = async (e) => {



        e.preventDefault()


        if(submitData?.phone?.length !== 11){
            Notify("Phone number must be 11 digit", "error")
            return null
        }

        if (isNaN(submitData?.phone))
        {
            Notify("Phone number must be a number", "error")
            return null
        }

        if(!submitData?.password){
            Notify("Please enter your password", "error")
            return null
        }

        const res = await APIS.Auth.Login({
            ...submitData,
            ...{phone:`+88${submitData?.phone}`}
        })

        if(res){
            Notify("Welcome Back")
            OnLoginSuccess(res)
            // return redirect("/grounds");
            navigate('/')
        }

    }

    

    return (
        <>
        <Header />
        <div class=" authendication-pages" style={{marginTop:80}} >
            <div className="content">
                <div className="container wrapper no-padding">
                    <div className="row no-margin vph-100">
                        <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                            <div className="col-sm-8 col-md-6 col-lg-6 mx-auto">
                                {/* <header className="text-center">
                                    <a href="user-dashboard.html">
                                        <img src="assets/img/logo-black.svg" className="img-fluid" alt="Logo" />
                                    </a>
                                </header> */}
                                <div className="shadow-card">
                                    <h2>Welcome Back</h2>
                                    <p>Login into your account</p>

                                    <div className="tab-content mt-5" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="user"
                                            role="tabpanel"
                                            aria-labelledby="user-tab"
                                        >
                                            <form onSubmit={Submit} >
                                                <div className="form-group">
                                                    <div className="group-img">
                                                        <i className="feather-user" />
                                                        <input
                                                            value={submitData?.phone}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Your phone number"
                                                            onChange={e => SetsubmitData({...submitData, ...{phone:e.target.value}})}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="pass-group group-img">
                                                        <i 
                                                            onClick={() => SetshowPass(!showPass)}
                                                            className={`toggle-password feather-eye${!showPass ? "-off" : ""}` }
                                                        />
                                                        <input
                                                            value={submitData?.password}
                                                            type={showPass ? "text" : "password"}
                                                            className="form-control pass-input"
                                                            placeholder="Password"
                                                            onChange={e => SetsubmitData({...submitData, ...{password:e.target.value}})}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group d-sm-flex align-items-center justify-content-between">
                                                    <div className="form-check form-switch d-flex align-items-center justify-content-start">
                                                    </div>
                                                    <span>
                                                        <Link to="/forget-password" className="forgot-pass">
                                                            Forgot Password
                                                        </Link>
                                                    </span>
                                                </div>
                                                <button
                                                    className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                                                    type="submit"
                                                    onClick={Submit}
                                                >
                                                    Sign In
                                                    <i className="feather-arrow-right-circle ms-2" />
                                                </button>
                                                
                                            </form>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="bottom-text text-center">
                                    <p>
                                        Don’t have an account? <a href="/signup">Sign up!</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}