import { useState } from "react"
import { useSelector } from "react-redux"
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom"
import APIS from "../apis"
import Footer from "../component/Footer"
import Header from "../component/Header"
import { Notify, OnLoginSuccess } from "../uti/uti"


export default function PaymentDone() {

    const navigate = useNavigate()

    const token = useSelector(state => state?.auth?.token)

    const [searchParams, setSearchParams] = useSearchParams()

    const status = searchParams.get('status')

    return (
        <>
        <Header />
        <div class=" authendication-pages" style={{marginTop:80}} >
            <div className="content">
                <div className="container wrapper no-padding">
                    <div className="row no-margin vph-100">
                        <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                            <div className="col-sm-8 col-md-6 col-lg-6 mx-auto">
                                {/* <header className="text-center">
                                    <a href="user-dashboard.html">
                                        <img src="assets/img/logo-black.svg" className="img-fluid" alt="Logo" />
                                    </a>
                                </header> */}
                                <div className="shadow-card text-center">
                                    <h2>{status == 'success' ? "Payment Success" : "Payment Failed"}</h2>
                                    {status == 'success' ? 
                                        <p>Your payment has been sent successfully</p>
                                    :
                                        <p>Payment failed! Please try again form <Link to='/my-bookings' ><b>your booking</b></Link></p>
                                    }
                                    <i className={status == 'success' ? "feather-check-circle" : "feather-alert-triangle" } style={{fontSize:72, color:status == 'success' ? 'green' : 'red'}}  />



                                    {/* <div style={{display:'flex', flexDirection:'row', marginTop:50}} > */}
                                    <div style={{flexDirection:'row', maxWidth:"50%", margin:"20px auto"}} >
                                        <Link to="/my-bookings" >
                                            <button
                                                className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                                                type="href"
                                            >
                                                See Your Bookings
                                                <i className="feather-arrow-right-circle ms-2" />
                                            </button>
                                        </Link>
                                    <div style={{height:15}} />
                                        <Link to="/" >
                                            <button
                                                className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                                                type="submit"
                                            >
                                                Go to Home
                                                <i className="feather-arrow-right-circle ms-2" />
                                            </button>
                                        </Link>
                                    </div>
                                    


                                    <div className="tab-content mt-5" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="user"
                                            role="tabpanel"
                                            aria-labelledby="user-tab"
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}