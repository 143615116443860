import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token:"",
  userId:"",
  user:{},
  authChecked:false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    UpdateAuthChecked:(state, actions)  => {
      state.authChecked = true
    },
    SaveLogin:(state, actions) => {
        state.token = actions.payload.token
        state.userId = actions.payload.user._id
        state.user = actions.payload.user
    },
    ResetAuth:(state, actions) => {
      state.token = ""
      state.userId = ""
      state.user = {}
    },
    UpdateAuthUser:(state, actions) => {
      state.user = actions?.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { 
  SaveLogin,
  ResetAuth,
  UpdateAuthChecked,
  UpdateAuthUser
} = authSlice.actions

export default authSlice.reducer