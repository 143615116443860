import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.min.css';

import "./assets-dream/plugins/fontawesome/css/fontawesome.min.css"
import "./assets-dream/plugins/fontawesome/css/all.min.css"
import "./assets-dream/css/feather.css"

import "./assets-dream/plugins/select2/css/select2.min.css"

import './assets/css/Dream.css';

import "react-multi-carousel/lib/styles.css";


import 'react-toastify/dist/ReactToastify.css';


import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


import './assets/css/style.css';

import logoMain from './assets/images/logo.png'
import Ground1 from './assets/images/ground1.png'
import Ground2 from './assets/images/ground2.png'
import Ground3 from './assets/images/ground3.png'
import Ground4 from './assets/images/ground4.png'
import UserDashboard from './screens/UserDashboard';
import Grounds from './screens/Grounds';
import Header from './component/Header';
import Footer from './component/Footer';
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from './screens/Home';
import Login from './screens/Login';
import SignUp from './screens/SignUp';
import GroundsDetails from './screens/GroundsDetails';
import { ToastContainer } from 'react-toastify';
import { Provider, useSelector } from 'react-redux';
import { store } from './redux/store';
import { useEffect } from 'react';
import { ChkAuth } from './uti/uti';
import BookGround from './screens/BookGround';
import Blogs from './screens/Blogs';
import BlogDetails from './screens/BlogDetails';
import MyFavourites from './screens/MyFavourites';
import ForgetPassword from './screens/ForgetPassword';
import AboutUs from './screens/AboutUs';
import MyBookings from './screens/MyBookings';
import MyProfile from './screens/MyProfile';
import ChangePassword from './screens/ChangePassword';
import PaymentDone from './screens/PaymentDone';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/paymentdone",
    element: <PaymentDone />,
  },
  {
    path: "/grounds",
    element: <Grounds />,
  },
  {
    path: "/grounds/:slug",
    element: <GroundsDetails />,
  },
  {
    path: "/book-ground/:slug",
    element: <BookGround />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/blogs/:slug",
    element: <BlogDetails />,
  },
  {
    path: "/dashboard",
    element: <UserDashboard />,
  },
  {
    path: "/my-favourites",
    element: <MyFavourites />,
  },
  {
    path: "/my-bookings",
    element: <MyBookings />,
  },
  {
    path: "/my-profile",
    element: <MyProfile />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signUp",
    element: <SignUp />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  }
  
]);

const Nav = () => {

  const authChecked = useSelector(state => state?.auth?.authChecked)

  useEffect(() => {
    ChkAuth()
  }, [])

  if(!authChecked){
    return <div class="customLoder">Loading</div>
  }

  return (
    <RouterProvider 
      router={router}
    />
  )
}


function App() {

  return (
    <>
      <Provider store={store} >
        <Nav />
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;
