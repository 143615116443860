import axios from "axios";
import { BASE_URL, Notify } from "../uti/uti";
import { CallAuthGet, CallAuthPost } from "./lib";

export async function GroundReviews(groundId){

    try{
        
        const res = await axios.get(BASE_URL+`/ground-reviews/${groundId}`)

        console.log(res)

        if(res?.data?.success){
            return res?.data?.result
        }

        Notify(res?.data?.message, "error")

        return false


    }catch(e){

        console.log(e)

        Notify("Something went wrong", "error")

        return false

    }
    
}

export async function GroundBySlug(slug){

    try{
        
        const res = await axios.get(BASE_URL+`/grounds/${slug}`)

        console.log(res)

        if(res?.data?.success){
            return res?.data?.result
        }

        Notify(res?.data?.message, "error")

        return false


    }catch(e){

        console.log(e)

        Notify("Something went wrong", "error")

        return false

    }
    
}

export async function GroundBookingHistory(_id, startDate, endDate){

    try{

        const res = await axios.get(BASE_URL + `/grounds/book-history/${_id}?startDate=${startDate}&endDate=${endDate}`)

        console.log("res", res)

        if(res?.data?.success){

            return res?.data?.result

        }else{
            
            Notify(res?.data?.message, "error")

            return false

        }

    }catch(e){

        Notify("Something went wrong", "error")

        return false

    }
    
}

export async function Grounds(searchKey, filterData){

    try{

        let url = BASE_URL+`/grounds?`

        if(searchKey){
            url = url + `searchKey=${searchKey}`
        }

        if(filterData?.searchDateStart && filterData?.searchDateEnd){
            url = url + `searchDateStart=${filterData?.searchDateStart}&searchDateEnd=${filterData?.searchDateEnd}`
        }
        
        const res = await axios.get(url)

        console.log("grounds", res)

        if(res?.data?.success){
            return res?.data?.result
        }

        Notify(res?.data?.message, "error")

        return false


    }catch(e){

        console.log(e)

        Notify("Something went wrong", "error")

        return false

    }

}

export async function BookGround(payload){

    const res = await CallAuthPost('/ground-booking', payload)

    if(res){
        return res
    }

    return false

}

export async function MakeAdvancePayment(bookingData){

    const res = await CallAuthGet(`/payment/bkash/make-payment?bookingId=${bookingData?._id}&paymentType=bookingAdvance`, {})

    if(res){
        return res
    }

    return false

}

export async function MakeFullDuePayment(bookingData){

    const res = await CallAuthGet(`/payment/bkash/make-payment?bookingId=${bookingData?._id}&paymentType=fullDue`, {})

    if(res){
        return res
    }

    return false

}


export async function MyBookings(){

    const res = await CallAuthGet('/ground-bookings')

    if(res){
        return res
    }

    return false

}
