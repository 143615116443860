import { useEffect, useState } from "react";
import APIS from "../apis";
import Footer from "../component/Footer";
import Header from "../component/Header";
import { Link, useParams } from "react-router-dom";
import { BASE_URL, ChkInWishList } from "../uti/uti";
import ImagePlaceholder from '../assets/images/placeholder-image.jpg'
import moment from "moment";


export default function BlogDetails() {

    const params = useParams()

    const [blogData, SetblogData] = useState({})
    const [data, Setdata] = useState([])

    useEffect(() => {
        LoadLatest()
        LoadData()
    }, [params?.slug])

    const LoadData = async () => {
        const res = await APIS.Blog.BlogBySlug(params?.slug)
        if (res) {
            SetblogData(res)
        }
    }

    const LoadLatest = async () => {
        const res = await APIS.Blog.Blogs()
        if (res) {
            Setdata(res)
        }
    }

    return (
        <>
            <Header />

            <div className="content blog-details" style={{ marginTop: 100 }} >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4 blog-sidebar theiaStickySidebar">
                            <div className="card">
                                <h4>Latest Posts</h4>
                                <ul className="latest-posts">
                                    {data?.map(ele =>
                                        <li>
                                            <div className="post-thumb">
                                                <Link to={"/blogs/" + ele?.slug}>
                                                    <img
                                                        className="img-fluid"
                                                        src={ele.thumbImage ? BASE_URL + '/' + ele.thumbImage : ImagePlaceholder}
                                                        alt="Post"
                                                    />
                                                </Link>
                                            </div>
                                            <div className="post-info">
                                                <h6 style={{ marginTop: 7, marginBottom: 3 }} >
                                                    <Link to={`/blogs/${ele?.slug}`}>
                                                        {ele?.title}
                                                    </Link>
                                                </h6>
                                                <p>{moment(ele?.createdAt).fromNow()}</p>

                                            </div>
                                        </li>
                                    )}

                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <div className="featured-venues-item">
                                <div className="listing-item blog-info">
                                    {/* <div className="listing-img">
                                        <a href="blog-details.html">
                                            <img
                                                src="assets/img/blog/blog-04.jpg"
                                                className="img-fluid"
                                                alt="Venue"
                                            />
                                        </a>
                                        <div className="fav-item-venues news-sports">
                                            <span className="tag tag-blue">Badminton</span>
                                        </div>
                                    </div> */}
                                    <div className="listing-content news-content">
                                        <div className="listing-venue-owner blog-detail-owner d-lg-flex justify-content-between align-items-center">
                                            <div className="navigation">
                                                {/* <a href="javascript:void(0)">
                                                    <img src="assets/img/profiles/avatar-01.jpg" alt="User" />
                                                    Orlando Waters
                                                </a> */}
                                                <span>
                                                    <i className="feather-calendar" />
                                                    {/* 15 May 2023 */}
                                                    {moment(blogData.createdAt).format("Do MMM YYYY")}
                                                </span>
                                                {/* <span>
                                                    <i className="far fa-comment-alt" />
                                                    30 Comments
                                                </span> */}
                                            </div>
                                            {/* <a className="btn btn-primary">
                                                <img
                                                    src="assets/img/icons/grid.svg"
                                                    className="img-fluid"
                                                    alt="Icon"
                                                />
                                                Rules of Game
                                            </a> */}
                                        </div>
                                        <h2 className="listing-title">
                                            
                                            {blogData?.title}
                                            
                                        </h2>
                                        <p>
                                            {blogData?.body}
                                            
                                        </p>
                                        
                                        
                                    </div>
                                </div>
                                {/* <div className="row align-items-center">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <div className="d-flex align-items-center tags-wrapper">
                                            <h6>Tags:</h6>
                                            <ul className="tags clearfix">
                                                <li>
                                                    <a href="#" className="tag">
                                                        Rackets
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="tag">
                                                        New Game
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" className="tag">
                                                        Dresses
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <div className="d-flex justify-content-lg-end align-items-center social-medias-wrapper">
                                            <h6>Share on :</h6>
                                            <ul className="social-medias d-flex">
                                                <li className="facebook">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li className="linkedin">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-linkedin" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-instagram" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-twitter" />
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="javascript:void(0);">
                                                        <i className="fa-brands fa-pinterest" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="blog-comments">
                                <div className="dull-bg author-widget">
                                    <div className="author-group d-md-flex align-items-center justify-content-start">
                                        <div className="profile-pic">
                                            <a href="javascript:void(0);" className="d-inline-block">
                                                <img src="assets/img/profiles/avatar-01.jpg" alt="User" />
                                            </a>
                                        </div>
                                        <div className="info">
                                            <span>Author</span>
                                            <h5>Antony Hilfn</h5>
                                            <p>
                                                Lorem Ipsum available but the or majority have that suffered
                                                alteration words which don't look even slightly believable.
                                                There are many available but the majority the have suffered
                                                alteration. There are many variations of dumm passages Lorem
                                                majority the have the suffered alteration.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="blog-comments">
                                <div className="dull-bg">
                                    <h4 className="mb-0">Comments (45)</h4>
                                    <hr />
                                    <ul>
                                        <li className="author-group d-md-flex align-items-center justify-content-start">
                                            <div className="profile-pic">
                                                <a href="javascript:void(0);" className="d-inline-block">
                                                    <img src="assets/img/profiles/avatar-04.jpg" alt="User" />
                                                </a>
                                            </div>
                                            <div className="info">
                                                <div className="head d-flex align-items-center justify-content-start">
                                                    <h5>Antony Hilfn</h5>
                                                    <i className="fa-solid fa-circle" />
                                                    <span>15 Mar 2023</span>
                                                </div>
                                                <p>
                                                    Lorem Ipsum available but the or majority have that suffered
                                                    alteration words which don't look even slightly believable.
                                                    There are many available but the majority the have suffered
                                                    alteration. There are many variations of dumm passages Lorem
                                                    majority the have the suffered alteration.
                                                </p>
                                                <a href="#">Reply</a>
                                            </div>
                                        </li>
                                        <li className="author-group d-md-flex align-items-center justify-content-start">
                                            <div className="profile-pic">
                                                <a href="javascript:void(0);" className="d-inline-block">
                                                    <img src="assets/img/profiles/avatar-05.jpg" alt="User" />
                                                </a>
                                            </div>
                                            <div className="info">
                                                <div className="head d-flex align-items-center justify-content-start">
                                                    <h5>Besant</h5>
                                                    <i className="fa-solid fa-circle" />
                                                    <span>15 Mar 2023</span>
                                                </div>
                                                <p>
                                                    Lorem Ipsum available but the or majority have that suffered
                                                    alteration words which don't look even slightly believable.
                                                    There are many available but the majority the have suffered
                                                    alteration. There are many variations of dumm passages Lorem
                                                    majority the have the suffered alteration.
                                                </p>
                                                <a href="#">Reply</a>
                                            </div>
                                        </li>
                                        <li className="author-group d-md-flex align-items-center justify-content-start">
                                            <div className="profile-pic">
                                                <a href="javascript:void(0);" className="d-inline-block">
                                                    <img src="assets/img/profiles/avatar-06.jpg" alt="User" />
                                                </a>
                                            </div>
                                            <div className="info">
                                                <div className="head d-flex align-items-center justify-content-start">
                                                    <h5>Maria Fin</h5>
                                                    <i className="fa-solid fa-circle" />
                                                    <span>15 Mar 2023</span>
                                                </div>
                                                <p>
                                                    Lorem Ipsum available but the or majority have that suffered
                                                    alteration words which don't look even slightly believable.
                                                    There are many available but the majority the have suffered
                                                    alteration. There are many variations of dumm passages Lorem
                                                    majority the have the suffered alteration.
                                                </p>
                                                <a href="#">Reply</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div className="card new-comment">
                                <h4>Enter Details</h4>
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter Name"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter Email Address"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            Phone Number
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="phonenumber"
                                            placeholder="Enter Phone Number"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="comments" className="form-label">
                                            Details
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="comments"
                                            rows={3}
                                            placeholder="Enter Comments"
                                            defaultValue={""}
                                        />
                                    </div>
                                    <button type="button" className="btn btn-gradient">
                                        Write Review
                                    </button>
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>


            <Footer />
        </>
    )
}