

import { Link, useLocation } from 'react-router-dom';
import logoFooter from './../assets/images/footer_logo.png'
import { useSelector } from 'react-redux';

function Footer() {

    const {token} = useSelector(state => state?.auth)

    const location = useLocation()

  return (

    <>
    
        <footer
            className="text-center text-lg-start text-white foot-bac"
            style={{ marginTop: "-5px" }}
        >
            {/* menu start */}
            <div className="container-lg py-5 pb-4">
            {/*Grid column*/}
            {/* <div className="col-lg-4 col-md-12">
            </div> */}
            {/*Grid column*/}
            {/*Grid row*/}
            <div className="row mt-3 gx-3 gx-md-5 gy-4">
                {/*Grid column*/}
                <div className="col-12 col-lg-5 col-md-12 mb-4 mb-md-0">
                    <img src={logoFooter} alt="" />

                    <p className="foot-text-left fw-semibold d-inline-block ">
                    At the heart of the bustling urban landscape, we've carved out a sanctuary for sports enthusiasts and athletes alike. In a world where the relentless rhythm of daily life often overshadows the need for play, our vision took shape - to create spaces where the spirit of sports reignites, and the thrill of the game comes alive.
                    </p>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                <div className="col-4 col-lg-2 col-md-6 mb-4 mt-lg-10 mb-md-0">
                    <ul className="list-unstyled mb-0 foot-text-right row gy-2">
                        <li>
                        <Link to="/" className="text-white">
                            Home
                        </Link>
                        </li>
                        <li>
                        <Link href="/grounds" className="text-white">
                            Playgrounds
                        </Link>
                        </li>
                        <li>
                        <Link href="/blogs" className="text-white">
                            News &amp; Events
                        </Link>
                        </li>
                    </ul>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                {/* <div className="col-4 col-lg-2 col-md-6 mb-4 mb-md-0">
                <ul className="list-unstyled mb-0 foot-text-right row gy-2">
                    <li>
                        <a href="#!" className="text-white">
                            News &amp; Events
                        </a>
                        <i className="feather-home" style={{fontSize:26}} />
                    </li>
                    <li>
                    <a href="#!" className="text-white">
                        Schedule
                    </a>
                    </li>
                    <li>
                    <a href="#!" className="text-white">
                        Contact
                    </a>
                    </li>
                </ul>
                </div> */}
                {/*Grid column*/}
                {/*Grid column*/}
                <div className="col-4 col-lg-5 col-md-6 mb-4 mb-md-0">
                <ul className="list-unstyled mb-0 foot-text-right row gy-2">

                    <li>
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}} >
                            FOLLOW US: 
                            <Link
                                to="https://www.facebook.com/TheSportsArenaTSA"
                                target='_blank'
                            >
                            <div className='social-btn' >
                                <i className="feather-facebook" style={{fontSize:26}} />
                            </div>
                            </Link>
                            <Link
                                to="https://www.instagram.com/thesportsarena.bd?igsh=Y2RsZWcxbm83aTdo"
                                target='_blank'
                            >
                            <div className='social-btn' >

                            <i className="feather-instagram" style={{fontSize:26}} />
                            </div>
                            </Link>
                        </div>
                        
                    </li>
                    <li>
                        <span>Call : +880 1970-491731</span>
                    </li>
                    <li>
                        <span>Email: thesportsarena.bd@gmail.com</span>
                    </li>
                    <li>
                        <span>Address: Shagufta Main Road, Block B, Road 3, Plot 217, Pallabi (Mirpur 12) , Dhaka, Bangladesh</span>
                    </li>
                    
                </ul>
                </div>
                {/*Grid column*/}
                {/*Grid column*/}
                {/* <div className="col-4 col-lg-2 col-md-6 mb-4 mb-md-0">
                <ul className="list-unstyled mb-0 foot-text-right row gy-2">
                    <li>
                    <a href="#!" className="text-white">
                        News &amp; Events
                    </a>
                    </li>
                    <li>
                    <a href="#!" className="text-white">
                        Schedule
                    </a>
                    </li>
                    <li>
                    <a href="#!" className="text-white">
                        Contact
                    </a>
                    </li>
                </ul>
                </div> */}
                {/*Grid column*/}
            </div>
            {/*Grid row*/}
            </div>
            {/* menu end */}
            {/* Copyright */}
            <div className="text-center py-4 foot-bor fw-semibold px-1">
            ALL RIGHTS ARE RESERVED BY THE SPORT ARENA 2023
            </div>
            {/* Copyright */}
        </footer>

        <div className="bottom-section">
            <Link to="/" className={`bottom-section-nav ${location.pathname == '/' ? 'active' : ""}`} >
                <i className="feather-home" style={{fontSize:26}} />
                <div>
                    HOME
                </div>
            </Link>
            <Link to="/grounds" className={`bottom-section-nav ${location.pathname == '/grounds' ? 'active' : ""}`} >
                <i className="feather-aperture" style={{fontSize:26}} />
                <div>
                    PLAYGROUNDS
                </div>
            </Link>
            <Link to="/blogs" className={`bottom-section-nav ${location.pathname == '/blogs' ? 'active' : ""}`} >
                <i className="feather-layout" style={{fontSize:26}} />
                <div>
                    New & Blogs
                </div>
            </Link>
            <Link to="/about-us" className={`bottom-section-nav ${location.pathname == '/about-us' ? 'active' : ""}`} >
                <i className="feather-info" style={{fontSize:26}} />
                <div>
                    ABOUT US
                </div>
            </Link>
            {token ?
                <Link to="/dashboard" className={`bottom-section-nav ${location.pathname == '/dashboard' ? 'active' : ""}`} >
                    <i className="feather-user" style={{fontSize:26}} />
                    <div>
                        DASHBOARD
                    </div>
                </Link>
            :
                <Link to="/login" className={`bottom-section-nav ${location.pathname == '/login' ? 'active' : ""}`} >
                    <i className="feather-user" style={{fontSize:26}} />
                    <div>
                        Sign In
                    </div>
                </Link>
            }
        </div>
        
    </>

  );
}

export default Footer;
