import { Updatewishlist } from "../redux/commonSlice";
import { store } from "../redux/store";
import { ChkInWishList, Notify } from "../uti/uti";
import { CallAuthDelete, CallAuthGet, CallAuthPost } from "./lib";


export async function MyWishlist(){

    const res = await CallAuthGet('/my-wishlist')

    console.log("wishlist", res)


    if(res?.success){

        store.dispatch(Updatewishlist(res?.result))

    }else{

        Notify(res?.message)

    }



}

export async function ToogleInWishList(_id){

    const {user} = store.getState().auth

    if(!user?._id){
        Notify("Please sign in to perform this action", 'error')
        return false
    }

    console.log()

    const res = ChkInWishList(_id) ? 
        await CallAuthDelete('/my-wishlist/'+_id)
    : 
        await CallAuthPost('/my-wishlist', {
            ground:_id,
            user:user?._id
        })

    if(res?.success){

        MyWishlist()

    }else{
    
        Notify(res?.message)

    }

}


