
import DashLogo from "./../assets-dream/img/icons/dashboard-icon.svg"
import MyBookingLogo from "./../assets-dream/img/icons/booking-icon.svg"
import ProfileLogo from "./../assets-dream/img/icons/profile-icon.svg"
import ChatLogo from "./../assets-dream/img/icons/chat-icon.svg"
import { Link, useNavigate, useParams, useRoutes } from "react-router-dom"
import { Logout } from "../uti/uti"

export default function DashboardHead({screenName}){

    const navigate = useNavigate()

    return (
        <div className="dashboard-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="dashboard-menu">
                  <ul>
                    <li>
                      <Link 
                        to="/dashboard"
                        className={screenName == 'dashboard' ? "active" : ""}
                      >
                        <img src={DashLogo} alt="Icon" />
                        <span>Dashboard</span>
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/my-bookings"
                        className={screenName == 'my-bookings' ? "active" : ""}
                      >
                        <img src={MyBookingLogo} alt="Icon" />
                        <span>My Bookings</span>
                      </Link>
                    </li>
                    <li>
                    <Link 
                        to="/my-favourites"
                        className={screenName == 'my-favourites' ? "active" : ""}
                      >
                        {/* <img src={MyBookingLogo} alt="Icon" /> */}
                        <i className="feather-heart" style={{fontSize:27}} />
                        <span>My Favourite</span>
                      </Link>
                    </li>
                    {/* <li>
                      <a href="user-chat.html">
                        <img src="assets/img/icons/chat-icon.svg" alt="Icon" />
                        <span>Chat</span>
                      </a>
                    </li>
                    <li>
                      <a href="user-invoice.html">
                        <img src="assets/img/icons/invoice-icon.svg" alt="Icon" />
                        <span>Invoices</span>
                      </a>
                    </li>
                    <li>
                      <a href="user-wallet.html">
                        <img src="assets/img/icons/wallet-icon.svg" alt="Icon" />
                        <span>Wallet</span>
                      </a>
                    </li> */}
                    <li>
                      <Link 
                        to="/my-profile"
                        className={screenName == 'my-profile' ? "active" : ""}
                      >
                        <img src={ProfileLogo} alt="Icon" />
                        <span>Profile Setting</span>
                      </Link>
                    </li>
                    <li>
                      <a 
                        onClick={() => Logout(navigate)}
                      >
                        <img src={ChatLogo} alt="Icon" />
                        <span>Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}