import Carousel from "react-multi-carousel";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CalenderIcon from './../assets-dream/img/icons/head-calendar.svg'
import ImagePlaceholder from './../assets/images/placeholder-image.jpg'
import APIS from "../apis";
import { BASE_URL, GetLowestPrice, GetPriceRange } from "../uti/uti";
import moment from "moment";
import _ from "lodash";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default function GroundsDetails() {

    const [groundData, SetGroundData] = useState({
        gallery: [],
        slots:[]
    })

    const [reviews, Setreviews] = useState([])

    const params = useParams()

    useEffect(() => {
        LoadDetails()
    }, [])

    const LoadDetails = async () => {
        const res = await APIS.Ground.GroundBySlug(params?.slug)
        console.log(res)
        if (res) {
            SetGroundData(res)
        }
    }

    useEffect(() => {
        if(groundData?._id){
            LoadReviews()
        }
    }, [groundData?._id])

    const LoadReviews = async () => {
        const res = await APIS.Ground.GroundReviews(groundData?._id)
        console.log(res)
        if (res) {
            Setreviews(res)
        }
    }

    return (
        <>
            <Header />
            <div className="venue-coach-details">
                {/* <div className="bannergallery-section">
                    <div className="main-gallery-slider owl-carousel owl-theme">
                    <div className="gallery-widget-item">
                        <a
                        href="assets/img/gallery/gallery1/gallery-01.png"
                        data-fancybox="gallery1"
                        >
                        <img
                            className="img-fluid"
                            alt="Image"
                            src="assets/img/gallery/gallery1/gallery-01.png"
                        />
                        </a>
                    </div>
                    <div className="gallery-widget-item">
                        <a
                        href="assets/img/gallery/gallery1/gallery-02.png"
                        data-fancybox="gallery1"
                        >
                        <img
                            className="img-fluid"
                            alt="Image"
                            src="assets/img/gallery/gallery1/gallery-02.png"
                        />
                        </a>
                    </div>
                    <div className="gallery-widget-item">
                        <a
                        href="assets/img/gallery/gallery1/gallery-03.png"
                        data-fancybox="gallery1"
                        >
                        <img
                            className="img-fluid"
                            alt="Image"
                            src="assets/img/gallery/gallery1/gallery-03.png"
                        />
                        </a>
                    </div>
                    <div className="gallery-widget-item">
                        <a
                        href="assets/img/gallery/gallery1/gallery-04.png"
                        data-fancybox="gallery1"
                        >
                        <img
                            className="img-fluid"
                            alt="Image"
                            src="assets/img/gallery/gallery1/gallery-04.png"
                        />
                        </a>
                    </div>
                    <div className="gallery-widget-item">
                        <a
                        href="assets/img/gallery/gallery1/gallery-05.png"
                        data-fancybox="gallery1"
                        >
                        <img
                            className="img-fluid"
                            alt="Image"
                            src="assets/img/gallery/gallery1/gallery-05.png"
                        />
                        </a>
                    </div>
                    <div className="gallery-widget-item">
                        <a
                        href="assets/img/gallery/gallery1/gallery-01.png"
                        data-fancybox="gallery1"
                        >
                        <img
                            className="img-fluid"
                            alt="Image"
                            src="assets/img/gallery/gallery1/gallery-01.png"
                        />
                        </a>
                    </div>
                    <div className="gallery-widget-item">
                        <a
                        href="assets/img/gallery/gallery1/gallery-02.png"
                        data-fancybox="gallery1"
                        >
                        <img
                            className="img-fluid"
                            alt="Image"
                            src="assets/img/gallery/gallery1/gallery-02.png"
                        />
                        </a>
                    </div>
                    </div>
                    <div className="showphotos corner-radius-10">
                    <a
                        href="assets/img/gallery/gallery1/gallery-03.png"
                        data-fancybox="gallery1"
                    >
                        <i className="fa-regular fa-images" />
                        More Photos
                    </a>
                    </div>
                </div> */}

                {groundData?.gallery?.length > 0 ?
                    <div
                        style={{ marginTop: 100, marginBottom: 20 }}
                    >
                        <Carousel
                            responsive={responsive}
                            infinite
                            draggable={false}
                            autoPlay
                        >
                            {groundData?.gallery?.map((ele, index) =>
                                <div style={{ padding: 5 }} >
                                    {/* <img src="https://falgunit.com/sports/assets/img/crckiet.jpeg" alt="Venue" /> */}
                                    <img src={BASE_URL + "/" + ele} alt="Venue" style={{ height: 300, width: '100%' }} />
                                </div>
                            )}


                        </Carousel>
                    </div>
                    : null}

                <div className="venue-info white-bg d-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <h1 className="d-flex align-items-center justify-content-start">
                                    {groundData?.name}
                                    <span className="d-flex justify-content-center align-items-center">
                                        <i className="fas fa-check-double" />
                                    </span>
                                </h1>
                                <ul className="d-sm-flex justify-content-start align-items-center">
                                    <li>
                                        <i className="feather-map-pin" />
                                        {groundData?.fullAddress}
                                    </li>
                                    <li>
                                        <i className="feather-phone-call" />
                                        {groundData?.contactPhone}
                                    </li>

                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-right">
                                <ul className="social-options float-lg-end d-sm-flex justify-content-start align-items-center">

                                    <li>
                                        <a href="javascript:void(0);" className="favour-adds">
                                            <i className="feather-star" />
                                            Add to favourite
                                        </a>
                                    </li>
                                    <li className="venue-review-info d-flex justify-content-start align-items-center">
                                        <span className="d-flex justify-content-center align-items-center">
                                            5.0
                                            
                                        </span>
                                        <div className="review">
                                            <div className="rating">
                                                <i className="fas fa-star filled" />
                                                <i className="fas fa-star filled" />
                                                <i className="fas fa-star filled" />
                                                <i className="fas fa-star filled" />
                                                <i className="fas fa-star filled" />
                                            </div>
                                            <p className="mb-0">
                                                <a href="javascript:;">{groundData?.totalReviews} Reviews</a>
                                            </p>
                                        </div>
                                        <i className="fa-regular fa-comments" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="row bottom-row d-flex align-items-center">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">

                                {groundData?.bookingIns ? 
                                    <h3 className="primary-text mb-0 d-inline-block" style={{color:'#222'}}>
                                        {groundData?.bookingIns}
                                    </h3>
                                : null}
                                {/* <ul className="d-sm-flex details">
                            <li>
                            <div className="profile-pic">
                                <a href="javascript:void(0);" className="venue-type">
                                <img
                                    className="img-fluid"
                                    src="assets/img/icons/venue-type.svg"
                                    alt="Icon"
                                />
                                </a>
                            </div>
                            <div className="ms-2">
                                <p>Venue Type</p>
                                <h6 className="mb-0">Indoor</h6>
                            </div>
                            </li>
                            <li>
                            <div className="profile-pic">
                                <a href="javascript:void(0);">
                                <img
                                    className="img-fluid"
                                    src="assets/img/profiles/avatar-01.jpg"
                                    alt="Icon"
                                />
                                </a>
                            </div>
                            <div className="ms-2">
                                <p>Added By</p>
                                <h6 className="mb-0">Hendry Williams</h6>
                            </div>
                            </li>
                        </ul> */}
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="d-flex float-sm-end align-items-center">
                                    <p className="d-inline-block me-2 mb-0">{groundData?.slotPricingType == 'hour' ? "Per Person" : "Starts From"} :</p>
                                    <h3 className="primary-text mb-0 d-inline-block">
                                        {/* ৳{groundData?.slotPrice}<span>/ slot</span> */}
                                        {/* ৳{GetPriceRange(groundData?.slots)[0]}-{GetPriceRange(groundData?.slots)[1]} */}
                                        { groundData?.slotPricingType == 'hour' ? 
                                            <>৳{groundData?.slotPrice}/{groundData?.slotPricingType}</>
                                        :  
                                            <>
                                                ৳{GetPriceRange(groundData?.slots)[0]}-{GetPriceRange(groundData?.slots)[1]}
                                            </>
                                        }
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {groundData?.bookable ?
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                <div className="venue-options white-bg mb-4">
                                    <ul className="clearfix">
                                        <li className="active">
                                            <a href="#overview">Overview</a>
                                        </li>
                                        {/* <li>
                                            <a href="#includes">Includes</a>
                                        </li> */}
                                        <li>
                                            <a href="#genRules">General Rules</a>
                                        </li>
                                        <li>
                                            <a href="#rules">Game Rules</a>
                                        </li>
                                        <li>
                                            <a href="#addRules">Additional Rules</a>
                                        </li>
                                        {/* <li>
                                            <a href="#amenities">Amenities</a>
                                        </li> */}

                                        <li>
                                            <a href="#reviews">Reviews</a>
                                        </li>
                                        <li>
                                            <a href="#location">Locations</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="accordion" id="accordionPanel">
                                    <div className="accordion-item mb-4" id="overview">
                                        <h4 className="accordion-header" id="panelsStayOpen-overview">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseOne"
                                                aria-expanded="true"
                                                aria-controls="panelsStayOpen-collapseOne"
                                            >
                                                Overview
                                            </button>
                                        </h4>
                                        <div
                                            id="panelsStayOpen-collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-overview"
                                        >
                                            <div className="accordion-body">
                                                {/* <div className="text show-more-height"> */}
                                                <div className="text">
                                                    <p>
                                                        {groundData?.overView}
                                                    </p>
                                                </div>
                                                {/* <div className="show-more d align-items-center primary-text">
                                    <i className="feather-plus-circle" />
                                    Show More
                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="accordion-item mb-4" id="includes">
                                        <h4 className="accordion-header" id="panelsStayOpen-includes">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseTwo"
                                            >
                                                Includes
                                            </button>
                                        </h4>
                                        <div
                                            id="panelsStayOpen-collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-includes"
                                        >
                                            <div className="accordion-body">
                                                <ul className="clearfix">
                                                    {groundData?.includes?.map((ele, index) =>
                                                        <li key={index} >
                                                            <i className="feather-check-square" />
                                                            {ele}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="accordion-item mb-4" id="genRules">
                                        <h4 className="accordion-header" id="panelsStayOpen-rules">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                General Rules
                                            </button>
                                        </h4>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-rules"
                                        >
                                            <div className="accordion-body">
                                                <ul>
                                                    {groundData?.genRules?.map((ele, index) =>
                                                        <li>
                                                            <p>
                                                                <i className="feather-alert-octagon" />
                                                                {ele}
                                                            </p>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item mb-4" id="rules">
                                        <h4 className="accordion-header" id="panelsStayOpen-rules">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                Game Rules
                                            </button>
                                        </h4>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-rules"
                                        >
                                            <div className="accordion-body">
                                                <ul>
                                                    {groundData?.rules?.map((ele, index) =>
                                                        <li>
                                                            <p>
                                                                <i className="feather-alert-octagon" />
                                                                {ele}
                                                            </p>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item mb-4" id="addRules">
                                        <h4 className="accordion-header" id="panelsStayOpen-rules">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseThree"
                                            >
                                                Additional Rules
                                            </button>
                                        </h4>
                                        <div
                                            id="panelsStayOpen-collapseThree"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-rules"
                                        >
                                            <div className="accordion-body">
                                                <ul>
                                                    {groundData?.addRules?.map((ele, index) =>
                                                        <li>
                                                            <p>
                                                                <i className="feather-alert-octagon" />
                                                                {ele}
                                                            </p>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* <div className="accordion-item mb-4" id="amenities">
                                        <h4 className="accordion-header" id="panelsStayOpen-amenities">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseFour"
                                            >
                                                Amenities
                                            </button>
                                        </h4>
                                        <div
                                            id="panelsStayOpen-collapseFour"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-amenities"
                                        >
                                            <div className="accordion-body">
                                                <ul className="clearfix">
                                                    {groundData?.amenities?.map((ele, index) =>
                                                        <li key={index} >
                                                            <i className="fa fa-check-circle" />
                                                            {ele}
                                                        </li>
                                                    )}

                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="accordion-item mb-4" id="reviews">
                                        <div className="accordion-header" id="panelsStayOpen-reviews">
                                            <div
                                                className="accordion-button d-flex justify-content-between align-items-center"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSix"
                                                aria-controls="panelsStayOpen-collapseSix"
                                            >
                                                <span className="w-75 mb-0">Reviews</span>
                                                {/* <a
                                                    href="javascript:void(0);"
                                                    className="btn btn-gradient pull-right write-review add-review"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#add-review"
                                                >
                                                    Write a review
                                                </a> */}
                                            </div>
                                        </div>
                                        <div
                                            id="panelsStayOpen-collapseSix"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-reviews"
                                        >
                                            <div className="accordion-body">
                                                {/* <div className="row review-wrapper">
                                                    <div className="col-lg-3">
                                                        <div className="ratings-info corner-radius-10 text-center">
                                                            <h3>4.8</h3>
                                                            <span>out of 5.0</span>
                                                            <div className="rating">
                                                                <i className="fas fa-star filled" />
                                                                <i className="fas fa-star filled" />
                                                                <i className="fas fa-star filled" />
                                                                <i className="fas fa-star filled" />
                                                                <i className="fas fa-star filled" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="recommended">
                                                            <h5>Recommended by 97% of Players</h5>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <p className="mb-0">Quality of service</p>
                                                                    <ul>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <span>5.0</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <p className="mb-0">Quality of service</p>
                                                                    <ul>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <span>5.0</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                                                    <p className="mb-0">Quality of service</p>
                                                                    <ul>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <span>5.0</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                                    <p className="mb-0">Quality of service</p>
                                                                    <ul>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <span>5.0</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                                    <p className="mb-0">Quality of service</p>
                                                                    <ul>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <i />
                                                                        </li>
                                                                        <li>
                                                                            <span>5.0</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {reviews?.map((ele, index) => 
                                                    <div className="review-box d-md-flex" key={index} >
                                                        <div className="review-profile">
                                                            <img 
                                                                src={ ele?.reviewedBy?.picture ? (BASE_URL+'/'+ele?.reviewedBy?.picture) : ImagePlaceholder }
                                                                alt="User" 
                                                            />
                                                        </div>
                                                        <div className="review-info">
                                                            <h6 className="mb-2 tittle">
                                                                {ele?.reviewedBy?.name} on {moment(ele?.createdAt).format("Do MMM YYYY")}
                                                            </h6>
                                                            <div className="rating">
                                                                {_.times(ele?.star).map((innerEle, innerIndex) => 
                                                                    <i key={innerIndex} className="fas fa-star filled" />
                                                                )}
                                                                {/* <i className="fas fa-star filled" />
                                                                <i className="fas fa-star filled" />
                                                                <i className="fas fa-star filled" />
                                                                <i className="fas fa-star filled" /> */}
                                                                <span className=""> {parseFloat(ele?.star).toFixed(1)}</span>
                                                            </div>
                                                            {/* <h6>Absolutely perfect</h6> */}
                                                            <p>
                                                                {ele?.review}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* <div className="d-flex justify-content-center mt-1">
                                                    <button
                                                        type="button"
                                                        className="btn btn-load-more d-flex justify-content-center align-items-center"
                                                    >
                                                        Load More
                                                        <i className="feather-plus-square" />
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="accordion-item" id="location">
                                        <h4 className="accordion-header" id="panelsStayOpen-location">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#panelsStayOpen-collapseSeven"
                                                aria-expanded="false"
                                                aria-controls="panelsStayOpen-collapseSeven"
                                            >
                                                Location
                                            </button>
                                        </h4>
                                        <div
                                            id="panelsStayOpen-collapseSeven"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="panelsStayOpen-location"
                                        >
                                            <div className="accordion-body">
                                                <div className="google-maps">
                                                    <iframe
                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2967.8862835683544!2d-73.98256668525309!3d41.93829486962529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89dd0ee3286615b7%3A0x42bfa96cc2ce4381!2s132%20Kingston%20St%2C%20Kingston%2C%20NY%2012401%2C%20USA!5e0!3m2!1sen!2sin!4v1670922579281!5m2!1sen!2sin"
                                                        height={445}
                                                        style={{ border: 0 }}
                                                        allowFullScreen=""
                                                        loading="lazy"
                                                        referrerPolicy="no-referrer-when-downgrade"
                                                    />
                                                </div>
                                                <div className="dull-bg d-flex justify-content-start align-items-center mt-3">
                                                    <div className="white-bg me-2">
                                                        <i className="fas fa-location-arrow" />
                                                    </div>
                                                    <div className="">
                                                        <h6>Our Venue Location</h6>
                                                        <p>70 Bright St New York, USA</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <aside className="col-12 col-sm-12 col-md-12 col-lg-4 theiaStickySidebar">
                                {groundData?.bookable ?
                                    <div className="white-bg d-flex justify-content-start align-items-center availability">
                                        <div>
                                            <span className="icon-bg">
                                                <img
                                                    className="img-fluid"
                                                    alt="Icon"
                                                    src={CalenderIcon}
                                                />
                                            </span>
                                        </div>
                                        <div>
                                            <h4>Availability</h4>
                                            <p className="mb-0">Check availability on your convenient time</p>
                                        </div>
                                        <div class="d-grid btn-block">
                                            <Link 
                                                to={`/book-ground/${groundData?.slug}`}
                                                class="btn btn-secondary d-inline-flex justify-content-center align-items-center">Check<i class="feather-arrow-right-circle ms-1"></i></Link>
                                        </div>
                                    </div>
                                : null }
                                <div className="white-bg book-court">
                                    <h4 className="border-bottom">Book This Ground</h4>
                                    <h5 className="d-inline-block">{groundData?.name}</h5>
                                    <ul className="d-sm-flex align-items-center justify-content-evenly">
                                        <li>
                                            <h3 className="d-inline-block primary-text">
                                                {/* ৳{groundData?.slotPrice} */}
                                                { groundData?.slotPricingType == 'hour' ? 
                                                    <>৳{groundData?.slotPrice}/{groundData?.slotPricingType}</>
                                                :  
                                                    <>
                                                        <span style={{color:'#000'}} >Starts From</span> ৳{GetLowestPrice(groundData?.slots)?.discountedPrice} <span style={{textDecoration:'line-through', color:'#000'}} >{GetLowestPrice(groundData?.slots)?.regularPrice}</span>
                                                    </>
                                                }
                                            </h3>
                                                
                                            {/* <span>/hr</span> */}
                                            {/* <p>up to 1 guests</p> */}
                                        </li>
                                        {/* <li>
                                <span>
                                <i className="feather-plus" />
                                </span>
                            </li> */}
                                        {/* <li>
                                <h4 className="d-inline-block primary-text">$5</h4>
                                <span>/hr</span>
                                <p>
                                each additional guest <br />
                                up to 4 guests max
                                </p>
                            </li> */}
                                    </ul>
                                    <div className="d-grid btn-block mt-3">
                                        <Link
                                            to={`/book-ground/${groundData?.slug}`}
                                            className="btn btn-secondary d-inline-flex justify-content-center align-items-center"
                                        >
                                            <i className="feather-calendar" />
                                            Book Now
                                        </Link>
                                    </div>
                                </div>
                               
                            </aside>
                        </div>
                    </div>
                   
                </div>
                : null }
             
            </div>
            <Footer />
        </>

    )
}