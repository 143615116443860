import { useEffect, useState } from "react";
import APIS from "../apis";
import { Link } from "react-router-dom";
import { BASE_URL, ChkInWishList, GetPriceRange } from "../uti/uti";


export default function SingleGround({ele, index}) {

    const [favourited, Setfavourited ] = useState(ChkInWishList(ele?._id))
    
    const OnPressFavourite = () => {
        Setfavourited(!favourited)
        APIS.User.ToogleInWishList(ele?._id)
    }

    console.log("GetPriceRange(ele?.slots)[0]", ele)

    return (
        <div 
            className="col-lg-12 col-md-12"
            key={index}
        >
            <div className="featured-venues-item venue-list-item">
                <div className="listing-item listing-item-grid">
                    <div className="listing-img">
                        <Link to={"/grounds/"+ele?.slug} >
                            {/* <img src={"https://falgunit.com/sports/assets/img/crckiet.jpeg"} alt="Venue" /> */}
                            <img 
                                src={BASE_URL + "/" +ele?.gallery[0]} 
                                alt="Venue" 
                                // style={{maxWidth:400}}
                                className="single-ground-image"
                            />

                        </Link>
                        <div className="fav-item-venues">
                            <span className="tag tag-blue">Featured</span>
                            <h5 className="tag tag-primary">
                                {/* ৳{GetPriceRange(ele?.slots)[0]}-{GetPriceRange(ele?.slots)[1]} */}
                                { ele?.slotPricingType == 'hour' ? 
                                    <>৳{ele?.slotPrice}/{ele?.slotPricingType}</>
                                :  
                                    <>
                                        ৳{GetPriceRange(ele?.slots)[0]}-{GetPriceRange(ele?.slots)[1]}
                                    </>
                                }
                            </h5>
                        </div>
                    </div>
                    <div className="listing-content">
                        <div className="list-reviews">
                            <div className="d-flex align-items-center">
                                <span className="rating-bg">{parseFloat((ele?.totalReviews || 0)/(ele?.totalStar || 1)).toFixed(1)}</span>
                                <span>{ele?.totalReviews} Reviews</span>
                            </div>
                            <a onClick={OnPressFavourite} className={`fav-icon ${favourited ? 'active' : ''}`}>
                                <i className="feather-heart" />
                            </a>
                        </div>
                        <h3 className="listing-title">
                            <Link to={"/grounds/"+ele?.slug} >{ele?.name}</Link>
                        </h3>
                        <div className="listing-details-group">
                            <p>
                                {ele?.subTitle}
                            </p>
                            <ul className="listing-details-info">
                                <li>
                                    <span>
                                        <i className="feather-map-pin" />
                                        {ele?.fullAddress}
                                    </span>
                                </li>
                                {/* <li>
                                    <span>
                                        <i className="feather-calendar" />
                                        Next availablity :{" "}
                                        <span className="primary-text">21 May 2023</span>
                                    </span>
                                </li> */}
                            </ul>
                        </div>
                        <div className="listing-button">
                            <div className="listing-venue-owner">
                                {/* <a className="navigation">
                                    <img src="assets/img/profiles/avatar-01.jpg" alt="User" />
                                    Mart Sublin
                                </a> */}
                            </div>
                            {ele?.bookable ? 
                            <Link to={`/book-ground/${ele?.slug}`} className="user-book-now">
                                <span>
                                    <i className="feather-calendar me-2" />
                                </span>
                                Book Now
                            </Link>
                            : null }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}