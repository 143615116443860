import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { GetMiddleTime, GetWeekDates, JoinDateNTime, OneHourSlots, Slots, timeOptions } from '../uti/uti';
import APIS from '../apis';

const Calendar = ({groundData, startDate, endDate, OnPressSlot, selectedSlots}) => {

  const [selectedDate, setSelectedDate] = useState(moment());


  const [data, SetData] = useState([])

  useEffect(() => {

    console.log("groundData", groundData)

    if(groundData?._id){
        LoadHistory()
    }


  }, [groundData, selectedDate])

  const LoadHistory = async () => {
    const res = await APIS.Ground.GroundBookingHistory(groundData?._id, moment(selectedDate).startOf('isoWeek').toISOString(), moment(selectedDate).endOf('isoWeek').toISOString())

    console.log(res)

    if(res){
        SetData(res)
    }
    
  }

  const CheckInBetween = (date) => {

    // console.log(date)

    // return data.find(ele => ele.startDate == date)

    let exist = data?.find(ele => moment(date).isBetween(moment(ele?.startDate), moment(ele?.endDate)))

    if(exist){
      console.log("exist", exist, date )
    }

    return exist

  }

  const ChkSlotStyle = (dateEle, slotStartTime, slotEndTime, slotMiddle) => {

    let active = null
    
    for (let index = 0; index < selectedSlots.length; index++) {

        if(moment(slotMiddle).isBetween(moment(selectedSlots[index].startDate), moment(selectedSlots[index].endDate))){
            active = {
                backgroundColor:"#ff0000",
                color:'#fff',
                textAlign:'center'
            }

            break
        }

    }

    return active

    // if(startDate && endDate){

    //     console.log("slotMiddle", slotMiddle)

    //     if(moment(slotMiddle).isBetween(moment(startDate), moment(endDate))){
    //         return active
    //     }

    // }else if(startDate){

    //     if(JoinDateNTime(dateEle, slotStartTime) == moment(startDate).toISOString()){
    //         return active
    //     }

    // }

    return null
  }

  console.log("date", moment("2023-12-01 07:00 AM", "YYYY-MM-DD hh:mm A").toLocaleString())
  console.log("date", moment("2023-12-01 07:00 PM", "YYYY-MM-DD hh:mm A").toISOString())

  return (
    <aside className="card booking-details timeslot-calender">
        <div style={{flexDirection:'row', display:'flex', alignItems:'center'}} >
            
            <button 
                type="button" 
                class="btn btn-primary"
                onClick={() => setSelectedDate(moment(selectedDate).subtract(7, 'days'))}
            >
                Previous
            </button>
            
            <h3 style={{flex:1, textAlign:'center', textTransform:'uppercase', margin:0}} >{moment(selectedDate).startOf('isoWeek').format("Do MMM YYYY")} - {moment(selectedDate).endOf('isoWeek').format("Do MMM YYYY")}</h3>

            <button 
                type="button" 
                class="btn btn-primary"
                onClick={() => setSelectedDate(moment(selectedDate).add(7, 'days'))}
            >
                Next
            </button>

        </div>
        <div className="table-responsive dashboard-table-responsive">
            <table className="table dashboard-card-table">
                {/* <tbody>
                    <tr>
                        <td></td>
                        {GetWeekDates(selectedDate)?.map(ele =>
                            <td>{ele?.format("Do MMM")}</td>
                        )}
                    </tr>
                    {timeOptions.slice(0, 14).map((ele, index) =>
                        <tr>
                          <td>
                              {ele?.label} - {timeOptions[index + 1]?.label}
                          </td>
                          {GetWeekDates(selectedDate)?.map(dateEle => {
                            const isBooked = CheckInBetween(GetMiddleTime(JoinDateNTime(dateEle, ele?.value), JoinDateNTime(dateEle, timeOptions[index + 1]?.value)))
                            return(
                              <td style={{}} >
                                {isBooked ? "Not avaliable" : <a className="tag" >Select</a>}
                              </td>
                            )
                          })}
                        </tr>
                    )}
                    
                </tbody> */}
                <tbody>
                    <tr>
                        <td></td>
                        {( groundData?.slotDurationMinutes == 60 ? OneHourSlots : Slots)?.map((ele, index) =>{
                            // if(index == 2){
                            //     return null
                            // }
                            return(
                            <td style={{fontSize:12, textAlign:'center'}} key={index} >
                                {ele?.label.split("-")[0].trim()}<br/>
                                TO <br/>
                                {ele?.label.split("-")[1].trim()}<br/>
                            </td>
                            )
                        })}
                    </tr>
                    {GetWeekDates(selectedDate)?.map((dateEle, index) =>
                        <tr
                            key={index}
                        >
                             <td>{dateEle?.format("Do MMM")}</td>

                             {(groundData?.slotDurationMinutes == 60 ? OneHourSlots : Slots)?.map((ele, slotIndex) => {

                                // if(index == 2){
                                //     return null
                                // }

                                const slotStartTime = ele?.start
                                const slotEndTime = ele?.end

                                const slotMiddle = GetMiddleTime(JoinDateNTime(dateEle, slotStartTime), JoinDateNTime(dateEle, slotEndTime))

                                const isBooked = CheckInBetween(slotMiddle)

                                const isActive = ChkSlotStyle(dateEle, slotStartTime, slotEndTime, slotMiddle)

                                return(
                                <td 
                                    key={slotIndex}
                                    style={isActive} 
                                    className="book-avaliable" 
                                    onClick={() => !isBooked ? OnPressSlot(dateEle, slotStartTime, slotEndTime, slotIndex + 1) : null} 
                                >
                                    {isBooked ? 
                                        <span className='booked'>Booked</span> 
                                    : 
                                        <span 
                                            // style={isActive} 
                                            onClick={() => OnPressSlot(dateEle, slotStartTime, slotEndTime, slotIndex + 1)} 
                                            // className='book-avaliable'
                                        >
                                            {isActive ? "Selected" : "Select"}
                                        </span>
                                    }
                                </td>
                                )
                          
                            })}
                            
                        </tr>
                    )}

                    {/* {timeOptions.slice(0, 14).map((ele, index) =>
                        <tr>
                          <td>
                              {ele?.label} - {timeOptions[index + 1]?.label}
                          </td>
                          {GetWeekDates(selectedDate)?.map(dateEle => {
                            const isBooked = CheckInBetween(GetMiddleTime(JoinDateNTime(dateEle, ele?.value), JoinDateNTime(dateEle, timeOptions[index + 1]?.value)))
                            return(
                              <td style={{}} >
                                {isBooked ? "Not avaliable" : <a className="tag" >Select</a>}
                              </td>
                            )
                          })}
                        </tr>
                    )} */}
                    
                </tbody>
            </table>
        </div>
    </aside>
  );
};

export default Calendar;