import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import commonSlice from './commonSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    common: commonSlice,
  },
})

