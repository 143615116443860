import * as Auth from './auth'
import * as Ground from './ground'
import * as User from './user'
import * as Blog from './blog'

const APIS = {
    Auth,
    Ground,
    User,
    Blog
}

export default APIS