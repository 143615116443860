import Footer from "../component/Footer";
import Header from "../component/Header";

import DashLogo from "./../assets-dream/img/icons/dashboard-icon.svg"
import MyBookingLogo from "./../assets-dream/img/icons/booking-icon.svg"
import ProfileLogo from "./../assets-dream/img/icons/profile-icon.svg"
import ChatLogo from "./../assets-dream/img/icons/chat-icon.svg"
import StateLogo from "./../assets-dream/img/icons/statistics-01.svg"
import ImagePlaceholder from './../assets/images/placeholder-image.jpg'

import { useEffect, useState } from "react";
import APIS from "../apis";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, Logout, Notify, UpdateProfileData } from "../uti/uti";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DashboardHead from "../component/DashboardHead";
import SingleGround from "../component/SingleGround";
import ProfileNav from "../component/ProfileNav";
import { CallAuthPost } from "../apis/lib";
import { UpdateAuthUser } from "../redux/authSlice";

export default function ChangePassword() {

  const user = useSelector(state => state?.auth?.user)
  const token = useSelector(state => state?.auth?.token)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [subData, SetsubData] = useState({
    oldPass:"",
    newPass:"",
    conPass:""
  })
  const [loading, Setloading] = useState(false)

  const Submit  = async () => {

    if(!subData?.oldPass){
      Notify("Please enter current password", "error")
      return null
    }

    if(!subData?.newPass){
      Notify("Please enter new password", "error")
      return null
    }

    if(subData?.newPass !== subData.conPass){
      Notify("Wrong Confirm password", "error")
      return null
    }

    Setloading(true)
    const res = await CallAuthPost("/auth/change-password", {
      password: subData?.oldPass,
      newPassword: subData?.newPass
    })
    Setloading(false)

    if(res?.success){
      Notify("Password updated")
    }else{
      Notify(res?.message)
    }
  
  }


  console.log("wishlist")

  return (
    <>
      <Header />
      <div className="" style={{marginTop:80}} >
        
        <DashboardHead 
          screenName="my-profile"
        />
        
        <div className="content court-bg">
          <div className="container">
            <ProfileNav 
              screenName="change-password"
            />
            <div className="row">
              <div className="col-sm-12">
                <div className="profile-detail-group">
                  <div className="card ">
                    <form>
                      <div className="row">
                        <div className="col-lg-7 col-md-7">
                          <div className="input-space">
                            <label className="form-label">Old Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              placeholder="Enter Old Password"
                              value={subData?.oldPass}
                              onChange={e => SetsubData({...subData, oldPass:e?.target?.value})}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                          <div className="input-space">
                            <label className="form-label">New Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="new-password"
                              placeholder="Enter New Password"
                              value={subData?.newPass}
                              onChange={e => SetsubData({...subData, newPass:e?.target?.value})}
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7">
                          <div className="input-space mb-0">
                            <label className="form-label">Confirm Password</label>
                            <input
                              type="password"
                              className="form-control"
                              id="confirm-password"
                              placeholder="Enter Confirm Password"
                              value={subData?.conPass}
                              onChange={e => SetsubData({...subData, conPass:e?.target?.value})}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="save-changes text-end">
                    <a 
                      onClick={() => SetsubData({
                        conPass:'',
                        newPass:'',
                        oldPass:''
                      })}   
                      className="btn btn-primary reset-profile"
                    >
                      Reset
                    </a>
                    <a onClick={Submit} className="btn btn-secondary save-profile">
                      {loading ? "Saving" : "Save Change"}
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      
      </div>
      <Footer />
    </>

  )
}