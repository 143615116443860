
import moment from 'moment'
import { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel'
import { Link } from 'react-router-dom'
import APIS from '../apis'
import CounterInView from '../component/CounterUp'
import Footer from '../component/Footer'
import Header from '../component/Header'
import { BASE_URL, testimonials } from '../uti/uti'
import BosaImage from './../assets/images/ai/asolsit.png'
import KickingImage from './../assets/images/ai/kicking.png'
import Redoutline from './../assets/images/ai/routline.png'
import Whiteoutline from './../assets/images/ai/woutline.png'
import AppStoreBtn from './../assets/images/appstorebtn.png'
import FeatureImage from './../assets/images/feature_image1.png'
import FeatureImage2 from './../assets/images/feature_image2.png'
import ImagePlaceholder from './../assets/images/placeholder-image.jpg'
import PlayStoreBtn from './../assets/images/playstorebtn.png'

const TestimonialResponsive = {
  superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
  },
  desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 3
  },
  tablet: {
      breakpoint: { max: 1400, min: 767 },
      items: 2
  },
  mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
  }
};

export default function Home() {

  const [data, Setdata] = useState([])
  const [blogs, Setblogs] = useState([])

    useEffect(() => {
        LoadGrounds()
        LoadBlogs()
    }, [])

    const LoadGrounds = async () => {
        const res = await APIS.Ground.Grounds()
        if(res){
            Setdata(res)
        }
    }

    const LoadBlogs = async () => {
      const res = await APIS.Blog.Blogs()
      if(res){
        Setblogs(res)
      }
  }

    const features = [
      {name:"BD largest Indoor Sports Ground", image: FeatureImage},
      {name:"7 -A -Side Sports Ground", image: FeatureImage2},
      {name:"Swimming Pool", image: FeatureImage2},
      {name:"Changing Room", image: FeatureImage},
      {name:"Juice bar", image: FeatureImage2},
      {name:"Active Support Team", image: FeatureImage},
      {name:"Booking Through App", image: FeatureImage2},
      {name:"Online Payment System", image: FeatureImage},
    ]

    return(

        <>

        <Header />
        
        {/* banner section start */}
        <section className='overflow-100' >
          <div className="bg-banner position-relative">
            {/*hero section start */}
            <div className="">
              <div className="text-white container-lg px-2 px-sm-5 px-lg-0">
                <h4 className="border border-white px-2 py-2 banner-text-1 d-inline-block fw-light">
                  OWN THE GROUND
                </h4>
                <h3 className="banner-text-2 fw-semibold">
                  UNLEASH YOUR FULL POTENTIAL
                </h3>
                <Link
                    to='/grounds'
                >
                  <button 
                    className="px-4 py-1 mx-1 border-0 text-white hero-btn"
                  >
                    BOOK NOW
                  </button>
                </Link>
              </div>
            </div>
            <div className='triangle' />
          </div>
            {/*hero section end */}
          <div style={{paddingBottom:100}} >
            {/* about us section start */}
            <div className="container-lg px-2 px-sm-5 px-lg-0">
              <div className="main d-flex justify-content-between">
                <div className="about-text about-top" style={{zIndex:2}} >
                  <h5 className="fw-semibold">ABOUT US</h5>
                  <h6 className="fw-semibold">WHO ARE WE</h6>
                  <p className="pt-3 fw-semibold">
                  At the heart of the bustling urban landscape, we've carved out a sanctuary for sports enthusiasts and athletes alike. In a world where the relentless rhythm of daily life often overshadows the need for play, our vision took shape - to create spaces where the spirit of sports reignites, and the thrill of the game comes alive.
                  </p>
                </div>
                <div
                  style={{position:'relative', zIndex:1}}
                >
                  
                  <h1
                    style={{fontSize:200, color:'#eee', position:'absolute', textAlign:'right', top:-100, right:0, zIndex:-1, width:700}}
                  >WHO<br />ARE WE</h1>
                  <img 
                    src={BosaImage} 
                    style={{height:500, zIndex:2}}
                    className='floatingImage'
                  />
                </div>

                {/* <div class="about-image w-50 d-flex justify-content-end">
                        </div> */}
              </div>
            </div>
            {/* about us section end */}
            {/* facts and figure section start */}
            <div className="container-lg px-2 px-sm-5 px-lg-0 facts-figures">
              <div className="d-flex align-items-center justify-content-center">
                <h6 className="counter-head fw-light text-white d-inline-block p-2">
                  FACTS &amp; FIGURES
                </h6>
              </div>
              <div className="d-flex flex-row flex-sm-row flex-md-row pt-5 justify-content-between">
                {/* counter */}
                <div className="counter d-flex flex-column align-items-center">
                  <div className="counter_value fw-medium">
                    <CounterInView 
                      end={20}
                    />
                  </div>
                  {/* <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                    {({ isVisible }) => (
                        <div className="counter_value fw-medium">
                        {isVisible ? <CountUp end={50} /> : 0}
                        </div>
                    )}
                  </VisibilitySensor> */}
                  <h6 className="counter_text fw-normal">ASSOCIATES</h6>
                </div>
                {/* end counter */}
                {/* counter */}
                <div className="counter d-flex flex-column align-items-center">
                  <div className="counter_value fw-medium">
                    <CounterInView 
                      end={3}
                    />
                  </div>
                  <h6 className="counter_text fw-normal">GROUNDS</h6>
                </div>
                {/* end counter */}
                {/* counter */}
                <div className="counter d-flex flex-column align-items-center">
                  <div className="counter_value fw-medium">
                    <CounterInView 
                      end={14}
                    />
                  </div>
                  <h6 className="counter_text fw-normal">SLOTS</h6>
                </div>
                {/* end counter */}
                {/* counter */}
                <div className="counter d-flex flex-column align-items-center">
                  <div className="counter_value fw-medium">
                    <CounterInView 
                      end={15}
                    />
                  </div>
                  <h6 className="counter_text fw-normal">SUPPORT TEAM</h6>
                </div>
                {/* end counter */}
              </div>
            </div>
            {/* facts and figure section start */}
          </div>
        </section>
        {/* banner section end */}
        {/* playground section start */}

        <section
          className='overflow-100 ground-section'
        >

          <img 
            src={Whiteoutline}
            style={{
              position:'absolute',
              height:742,
              top:-200,
              right:-200,
              width:1000,
              opacity:0.6,
              zIndex:-1
            }}
          />

          <img 
            src={Whiteoutline}
            style={{
              position:'absolute',
              height:742,
              bottom:-200,
              left:-200,
              width:1000,
              opacity:0.6,
              zIndex:-1
            }}
          />

          <div className="container-lg px-1 px-sm-5 px-lg-0" style={{zIndex:2}} >
            <div className="text-center play_ground_top_text mt-lg-4" style={{zIndex:2}} >
                <h6 className="lh-1 fw-semibold">CHOOSE</h6>
                <h6 className="lh-1 fw-semibold">THE GROUNDS</h6>
            </div>
            <div className="row row-cols-1 row-cols-sm-3 row-cols-lg-4 g-2 g-sm-3 mt-3 mt-sm-1 mt-lg-5 px-1 sm:px-0">
                {data.slice(0, 4).map((ele, index) => 
                  <div 
                    className="col-lg-3 col-md-4 col-sm-6 col-xs-12 delGround"
                    key={index}
                  >
                    <Link to={`/grounds/${ele?.slug}`}>
                      <div className="card h-100 border-0 rounded-0 bg-transparent">
                        <div className="position-relative z-3">
                          <img
                            // src={Ground1}
                            src={!ele?.gallery[0] ? ImagePlaceholder : BASE_URL + "/" + ele?.gallery[0]}
                            className="card-img-top"
                            style={{height:300}}
                            alt={ImagePlaceholder}
                          />
                          <div className="card-img-overlay d-flex flex-column justify-content-end p-3 hover-overlay">
                            <h6 className="text-white fw-medium py-1 play_ground_img_text1">
                              CHECK AVAILABILITY
                            </h6>
                            <h3 className="text-white fw-semibold play_ground_img_text2 lh-1" style={{textTransform:'uppercase'}}  >
                              {ele?.name}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {/* <div className="col-3 delGround">
                  <a href="#">
                    <div className="card h-100 border-0 rounded-0 bg-transparent">
                      <div className="position-relative z-3">
                        <img
                          src={Ground2}
                          className="card-img-top"
                          alt="#"
                        />
                        <div className="card-img-overlay d-flex flex-column justify-content-end p-3 hover-overlay">
                          <h6 className="text-white fw-medium py-1 play_ground_img_text1">
                            CHECK AVAILABILITY
                          </h6>
                          <h3 className="text-white fw-semibold play_ground_img_text2 lh-1">
                            BADMINTON GROUND
                          </h3>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-3 delGround">
                  <a href="#">
                    <div className="card h-100 border-0 rounded-0 bg-transparent">
                      <div className="position-relative z-3">
                        <img
                          src={Ground3}
                          className="card-img-top"
                          alt="#"
                        />
                        <div className="card-img-overlay d-flex flex-column justify-content-end p-3 hover-overlay">
                          <h6 className="text-white fw-medium py-1 play_ground_img_text1">
                            CHECK AVAILABILITY
                          </h6>
                          <h3 className="text-white fw-semibold play_ground_img_text2 lh-1">
                            CRICKET GROUND
                          </h3>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-3 delGround">
                  <a href="#">
                    <div className="card h-100 border-0 rounded-0 bg-transparent">
                      <div className="position-relative z-3">
                        <img
                          src={Ground4}
                          className="card-img-top"
                          alt="#"
                        />
                        <div className="card-img-overlay d-flex flex-column justify-content-end p-3 hover-overlay">
                          <h6 className="text-white fw-medium py-1 play_ground_img_text1">
                            CHECK AVAILABILITY
                          </h6>
                          <h3 className="text-white fw-semibold play_ground_img_text2 lh-1">
                            SWIMMING POOL
                          </h3>
                        </div>
                      </div>
                    </div>
                  </a>
                </div> */}
            </div>
          </div>

          <h1 className="playground-bg-text" >PLAY<br />GROUND</h1>

          
        </section>

        <section

          className='overflow-100 feature-section'

        >

          <img 
            src={Redoutline}
            style={{
              position:'absolute',
              height:742,
              top:-200,
              right:-200,
              width:1000,
              zIndex:-1
            }}
          />

          <img 
            src={Redoutline}
            style={{
              position:'absolute',
              height:742,
              bottom:-200,
              left:-200,
              width:1000,
              zIndex:-1
            }}
          />

          {/* <div className="container-lg px-1 px-sm-5 px-lg-0" style={{zIndex:2}} >
            <div className="text-center play_ground_top_text mt-lg-4" style={{zIndex:2}} >
                <h6 className="lh-1 fw-semibold">CHOOSE</h6>
                <h6 className="lh-1 fw-semibold">THE GROUNDS</h6>
            </div>
          </div> */}

          <div className="container-lg px-1 px-sm-5 px-lg-0">
            {/* start title */}
            <div className="text-center play_ground_top_text text-black">
              <h6 className="fw-semibold mb-1" style={{color:'#000'}} >FEATURES</h6>
              <p className="fw-semibold mb-4 text-center mx-auto play_ground_text">
              More than just a futsal facility, The Sports Arena is a vibrant haven for individuals and teams to reconnect with their love for sports. Step into our world, where every moment is an opportunity to score, where sweat and laughter blend seamlessly, and where competition fuels your fire.
              </p>
            </div>
            {/* end title */}

            <div className='row' >

              {features?.map((ele, index) => 
                <div 
                  className="col-lg-3 col-md-4 col-sm-6" 
                  style={{marginBottom:20}}
                  key={index}
                >
                  <div className="card h-100 border-0 rounded-0">
                    <img
                      src={ele?.image}
                      className="card-img-top"
                      alt="#"
                    />
                    <div className="card-img-overlay feature-img-overlay d-flex flex-column justify-content-end ">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-2">
                        {ele?.name}
                      </p>
                    </div>
                  </div>
                </div>
              )}

                

            </div>

            {/* Card Start */}
            <div className="w-100" style={{display:'none'}} >
              <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-2 mt-1.5 px-1">
                <div className="col-3">
                  <div
                    className="card feature_card_bg_color border-0 rounded-0"
                    style={{ height: 316 }}
                  >
                    <div className="h-100 d-flex flex-column justify-content-end">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-3">
                        DOUBLE STORIED
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card h-100 border-0 rounded-0">
                    <img
                      src={FeatureImage}
                      className="card-img-top"
                      alt="#"
                    />
                    <div className="card-img-overlay feature-img-overlay d-flex flex-column justify-content-end ">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-2">
                        LIGHT SUPPORT
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card h-100 border-0 rounded-0">
                    <img
                      src="./assets/images/feature_image2.png"
                      className="card-img-top"
                      alt="#"
                    />
                    <div className="card-img-overlay feature-img-overlay d-flex flex-column justify-content-end ">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-2">
                        CARPET SUPPORT
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card h-100 border-0 rounded-0">
                    <img
                      src="./assets/images/feature_image1.png"
                      className="card-img-top"
                      alt="#"
                    />
                    <div className="card-img-overlay feature-img-overlay d-flex flex-column justify-content-end">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-2">
                        SHOWER PLACE
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card h-100 border-0 rounded-0">
                    <img
                      src="./assets/images/feature_image2.png"
                      className="card-img-top"
                      alt="#"
                    />
                    <div className="card-img-overlay feature-img-overlay d-flex flex-column justify-content-end">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-2">
                        NEAT &amp; CLEAN
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div
                    className="card feature_card_bg_color border-0 rounded-0"
                    style={{ height: 316 }}
                  >
                    <div className="h-100 d-flex flex-column justify-content-end">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-3">
                        DOUBLE STORIED
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card h-100 border-0 rounded-0">
                    <img
                      src="./assets/images/feature_image1.png"
                      className="card-img-top"
                      alt="#"
                    />
                    <div className="card-img-overlay feature-img-overlay d-flex flex-column justify-content-end">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-2">
                        SUPPORT TEAM
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="card h-100 border-0 rounded-0">
                    <img
                      src="./assets/images/feature_image2.png"
                      className="card-img-top bg-d"
                      alt="#"
                    />
                    <div className="card-img-overlay feature-img-overlay d-flex flex-column justify-content-end">
                      <p className="text-white fw-semibold feature_img_text lh-1 ps-2">
                        LIVE SUPPORT
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h1 className="feature-bg-text outlined-text" >OWN IT</h1>

          
        </section>

        <section

          className='overflow-100 news-section'

        >

          <img 
            src={Whiteoutline}
            style={{
              position:'absolute',
              height:742,
              top:-200,
              right:-200,
              width:1000,
              zIndex:-1,
              opacity:0.3
            }}
          />

          <img 
            src={Whiteoutline}
            style={{
              position:'absolute',
              height:742,
              bottom:-200,
              left:-200,
              width:1000,
              zIndex:-1,
              opacity:0.3
            }}
          />

          <img 
            src={KickingImage}
            style={{
              position:'absolute',
              bottom:0,
              width:1000,
              height:562.2,
              right:0,
              zIndex:-1
            }}
          />
         

          <h1 className="news-bg-text outlined-text" >UPDATE</h1>

          <div className="container-lg px-1 px-sm-5 px-lg-0" style={{zIndex:2}} >
            <div className="text-center play_ground_top_text mt-lg-4" style={{zIndex:2}} >
              <p className="fw-semibold text-center news_event_top_text">
                NEWS <span className="text-white">&amp; EVENTS</span>
              </p>
            </div>

            <div className="row">
                {blogs?.slice(0, 4)?.map((ele, index) => 
                  <div className="col-lg-4 mb-4 col-md-6 mb-lg-0">
                    <div className="card border-0 rounded-0">
                      <div className="card-body p-0 home-blog">
                        <img
                          // src={ImagePlaceholder}
                          src={ele?.thumbImage ? BASE_URL + "/" + ele.thumbImage : ImagePlaceholder }
                          alt=""
                          className="w-100 card-img-top"
                        />
                        <div className="px-2 mt-4 px-4 pb-2">
                          <p className="fw-medium news_event_text1 text-white d-inline-block px-1" style={{marginBottom:5}} >
                            FEATURED
                          </p>
                          <p className="fw-semibold lh-1 news_event_text2 text-black ellips-title" style={{marginBottom:5, textTransform:'uppercase'}} >
                            {ele?.title}
                          </p>
                          <p className="fw-semibold news_event_text3 lh-1" style={{textTransform:'uppercase'}} >
                            {moment(ele?.createAt).format("MMM DD, YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Link to="/blogs" >
                <button className="text-center text-white news_event_btn fw-semibold mx-auto border-0 px-3 d-flex justify-content-center align-items-center mt-5">
                  READ MORE ARTICLES
                </button>
              </Link>

              <p className="fw-semibold text-center client_top_text text-white" style={{marginTop:100}} >
                WHAT OUR <span className="client_top_text_col">CLIENTS</span> SAY
              </p>

              <div className="  g-3 mt-sm-5">
                <Carousel
                    responsive={TestimonialResponsive}
                    infinite
                    draggable={false}
                    autoPlay
                >
                    {testimonials?.map((ele, index) =>
                      <div style={{margin:5}} >
                          <div className="testimonial client_card_bg px-2 py-3 text-white text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="43.36"
                              height="34.427"
                              viewBox="0 0 43.36 34.427"
                            >
                              <g
                                id="Group_128"
                                data-name="Group 128"
                                transform="translate(-279.666 -4429.073)"
                              >
                                <path
                                  id="Path_59"
                                  data-name="Path 59"
                                  d="M279.666,4463.5q0-10.959.119-13.163.357-11.079,3.484-16.171t10.93-5.093l1.727,6.075q-4.289,0-5.807,2.889t-1.519,8.9h8.16V4463.5Zm26.266,0q0-10.959.119-13.163.359-11.079,3.485-16.171t10.929-5.093l1.728,6.075q-4.288,0-5.808,2.889t-1.518,8.9h8.159V4463.5Z"
                                  fill="red"
                                />
                              </g>
                            </svg>
                            <p className="mt-3 fw-medium client_card_text1">
                              {ele?.text}
                            </p>
                            <h5 className="pt-2 pb-4 fw-bold client_card_text2">
                              - {ele?.user}
                            </h5>
                          </div>
                        </div>
                    )}


                </Carousel>
                {/* <div className="col-md-4">
                  <div className="testimonial client_card_bg px-2 py-3 text-white text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="43.36"
                      height="34.427"
                      viewBox="0 0 43.36 34.427"
                    >
                      <g
                        id="Group_128"
                        data-name="Group 128"
                        transform="translate(-279.666 -4429.073)"
                      >
                        <path
                          id="Path_59"
                          data-name="Path 59"
                          d="M279.666,4463.5q0-10.959.119-13.163.357-11.079,3.484-16.171t10.93-5.093l1.727,6.075q-4.289,0-5.807,2.889t-1.519,8.9h8.16V4463.5Zm26.266,0q0-10.959.119-13.163.359-11.079,3.485-16.171t10.929-5.093l1.728,6.075q-4.288,0-5.808,2.889t-1.518,8.9h8.159V4463.5Z"
                          fill="red"
                        />
                      </g>
                    </svg>
                    <p className="mt-3 fw-medium client_card_text1">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    </p>
                    <h5 className="pt-2 pb-4 fw-bold client_card_text2">
                      - Alex Jeffson
                    </h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="testimonial client_card_bg px-2 py-3 text-white text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="43.36"
                      height="34.427"
                      viewBox="0 0 43.36 34.427"
                    >
                      <g
                        id="Group_128"
                        data-name="Group 128"
                        transform="translate(-279.666 -4429.073)"
                      >
                        <path
                          id="Path_59"
                          data-name="Path 59"
                          d="M279.666,4463.5q0-10.959.119-13.163.357-11.079,3.484-16.171t10.93-5.093l1.727,6.075q-4.289,0-5.807,2.889t-1.519,8.9h8.16V4463.5Zm26.266,0q0-10.959.119-13.163.359-11.079,3.485-16.171t10.929-5.093l1.728,6.075q-4.288,0-5.808,2.889t-1.518,8.9h8.159V4463.5Z"
                          fill="red"
                        />
                      </g>
                    </svg>
                    <p className="mt-3 fw-medium client_card_text1">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    </p>
                    <h5 className="pt-2 pb-4 fw-bold client_card_text2">
                      - George Washington
                    </h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="testimonial client_card_bg px-2 py-3 text-white text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="43.36"
                      height="34.427"
                      viewBox="0 0 43.36 34.427"
                    >
                      <g
                        id="Group_128"
                        data-name="Group 128"
                        transform="translate(-279.666 -4429.073)"
                      >
                        <path
                          id="Path_59"
                          data-name="Path 59"
                          d="M279.666,4463.5q0-10.959.119-13.163.357-11.079,3.484-16.171t10.93-5.093l1.727,6.075q-4.289,0-5.807,2.889t-1.519,8.9h8.16V4463.5Zm26.266,0q0-10.959.119-13.163.359-11.079,3.485-16.171t10.929-5.093l1.728,6.075q-4.288,0-5.808,2.889t-1.518,8.9h8.159V4463.5Z"
                          fill="red"
                        />
                      </g>
                    </svg>
                    <p className="mt-3 fw-medium client_card_text1">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    </p>
                    <h5 className="pt-2 pb-4 fw-bold client_card_text2">
                      - Christina Stark
                    </h5>
                  </div>
                </div> */}
              </div>

              <button className="text-center text-white mt-5 client_btn fw-semibold mx-auto border-0 px-3 d-flex justify-content-center align-items-center">
                CHECK MORE
              </button>

          </div>

          <div className="container-lg" style={{marginTop:150}} >
            <p className="fw-semibold text-white ms-lg-1 app_text_top lh-1">
              DOWNLOAD <span className="text_color">OUR APP</span>
            </p>

            <div className="d-md-flex align-content-center align-items-center gx-3">
              
              <a href="#">
                <img
                  src={AppStoreBtn}
                  alt=""
                  style={{ height: 82, marginRight:30 }}
                />
              </a>
              <a href="#">
                <img
                  src={PlayStoreBtn}
                  alt=""
                  className='playstore-link-image'
                />
              </a>
            </div>
          </div>

          
        </section>

       
        {/* playground section end */}
        {/* news & event section start */}
        
        {/* news & event section end */}
        {/* start map Section */}
        <div className="position-relative " style={{ marginTop: "-8px" }}  >
          <div className="SUBSCRIBE container px-3 sub-bac position-absolute start-50 translate-middle subscribe rounded-5">
            <form className="form-subscribe" action="#">
              <h3 className="text-white pb-2 sub-text fw-medium">
                SUBSCRIBE OUR NEWSLETTER <br /> TO STAY UPDATED
              </h3>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="subs-input-bg form-control input-lg rounded-start-4 py-md-2"
                />
                <span className="input-group-btn">
                  <button
                    className="text-white btn1 rounded-end-4 ms-1 ms-md-2 py-md-2 border-0 px-2 px-md-4 fw-semibold fs-2"
                    type="submit"
                  >
                    Subscribe
                  </button>
                </span>
              </div>
            </form>
          </div>
          <div className="contact">
            <div className="map">
              <img
                className="position-absolute start-50 translate-middle map-logo"
                src="./assets/images/map_logo.png"
                alt=""
              />
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14598.532627339515!2d90.3796147!3d23.8316405!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c73826e784eb%3A0x21065bf36abb4adc!2sThe%20Sports%20Arena!5e0!3m2!1sen!2sbd!4v1717393111784!5m2!1sen!2sbd"
                frameBorder={0}
                allowFullScreen=""
                
              />
            </div>
          </div>
        </div>
        {/* End Contact Section */}

        <Footer />

        </>

    )
}