import Footer from "../component/Footer";
import Header from "../component/Header";

import DashLogo from "./../assets-dream/img/icons/dashboard-icon.svg"
import MyBookingLogo from "./../assets-dream/img/icons/booking-icon.svg"
import ProfileLogo from "./../assets-dream/img/icons/profile-icon.svg"
import ChatLogo from "./../assets-dream/img/icons/chat-icon.svg"
import StateLogo from "./../assets-dream/img/icons/statistics-01.svg"
import ImagePlaceholder from './../assets/images/placeholder-image.jpg'

import { useEffect, useState } from "react";
import APIS from "../apis";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, Logout, Notify, UpdateProfileData } from "../uti/uti";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DashboardHead from "../component/DashboardHead";
import SingleGround from "../component/SingleGround";
import ProfileNav from "../component/ProfileNav";
import { CallAuthPost } from "../apis/lib";
import { UpdateAuthUser } from "../redux/authSlice";

export default function MyProfile() {

  const user = useSelector(state => state?.auth?.user)
  const token = useSelector(state => state?.auth?.token)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [userData, SetuserData] = useState(user)
  const [loading, Setloading] = useState(false)

  const Submit  = async () => {

    if(!userData?.name){
      return null
    }

    Setloading(true)
    const res = await CallAuthPost("/update-profile", userData)
    Setloading(false)

    if(res?.success){
      Notify("Profile updated")
      UpdateProfileData(res?.savedData)
    }
  
  }

  const OnSelectPhoto = async (e) => {

    const file = e.target.files[0]

    const formData = new FormData()

    formData.append("file", file)

    Setloading(true)

    const res = await CallAuthPost("/update-profile-photo", formData)

    console.log(res)

    Setloading(false)

    if(res?.success){
      Notify("Profile photo updated")
      UpdateProfileData(res?.savedData)
    }

    console.log("res", res)

  }


  console.log("wishlist")

  return (
    <>
      <Header />
      <div className="" style={{marginTop:80}} >
        
        <DashboardHead 
          screenName="my-profile"
        />
        
        <div className="content court-bg">
          <div className="container">
            <ProfileNav 
              screenName="my-profile"
            />
            <div className="row">
              <div className="col-sm-12">
                <div className="profile-detail-group">
                  <div className="card ">
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="file-upload-text">
                            <div className="file-upload" style={{padding:0, overflow:'hidden'}} >
                              <img
                                src={user?.picture ? BASE_URL + "/" +user?.picture : ImagePlaceholder}
                                className="img-fluid"
                                alt="Upload"
                                style={{
                                  height:163,
                                  width:182
                                }}
                              />
                              <p>Upload Photo</p>
                              <span>
                                <i className="feather-edit-3" />
                                <input 
                                  type="file" 
                                  id="file-input" 
                                  onChange={OnSelectPhoto}
                                />
                              </span>
                            </div>
                            <h5>
                              Upload your profile image (JPG, PNG, JPEG).
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input-space">
                            <label className="form-label">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Enter Name"
                              value={userData?.name}
                              onChange={e => SetuserData({...userData, name:e?.target?.value})}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                          <div className="input-space">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              placeholder="Enter Phone Number"
                              value={userData?.phone}
                              contentEditable={false}
                              disabled
                            />
                          </div>
                        </div>
                        
                      </div>
                    </form>
                  </div>
                  <div className="save-changes text-end">
                    <a 
                      onClick={() => SetuserData(user)}   
                      className="btn btn-primary reset-profile"
                    >
                      Reset
                    </a>
                    <a onClick={Submit} className="btn btn-secondary save-profile">
                      {loading ? "Saving" : "Save Change"}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      
      </div>
      <Footer />
    </>

  )
}