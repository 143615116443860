import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  wishlist:[]
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    Updatewishlist:(state, actions)  => {
      state.wishlist = actions.payload
    },
    ResetCommon:(state, actions) => {
      state.token = ""
      state.userId = ""
      state.user = {}
    }
  },
})

// Action creators are generated for each case reducer function
export const { 
  Updatewishlist,
  ResetCommon,
} = commonSlice.actions

export default commonSlice.reducer