import Footer from "../component/Footer";
import Header from "../component/Header";

import DashLogo from "./../assets-dream/img/icons/dashboard-icon.svg"
import MyBookingLogo from "./../assets-dream/img/icons/booking-icon.svg"
import ProfileLogo from "./../assets-dream/img/icons/profile-icon.svg"
import ChatLogo from "./../assets-dream/img/icons/chat-icon.svg"
import StateLogo from "./../assets-dream/img/icons/statistics-01.svg"
import { useEffect, useState } from "react";
import APIS from "../apis";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, Logout } from "../uti/uti";
import moment from "moment";
import { useSelector } from "react-redux";
import DashboardHead from "../component/DashboardHead";
import SingleGround from "../component/SingleGround";

export default function MyBookings() {

  const navigate = useNavigate()

  const token = useSelector(state => state?.auth?.token)

  const [data, Setdata] = useState([])
  const [payingBookId, SetpayingBookId] = useState("")

    useEffect(() => {

      if(token){
        LoadMyBooking()
      }
      
    }, [token])

    const LoadMyBooking = async () => {
        const res = await APIS.Ground.MyBookings()
        console.log(res)
        if(res){
          Setdata(res?.result)
        }
    }

    const Pay = async (bookigData) => {

      SetpayingBookId(bookigData?._id)

      const paymentRes = await APIS.Ground.MakeAdvancePayment(bookigData)

      if(paymentRes?.bkashPaymentData?.bkashURL){
          window.location.replace(paymentRes?.bkashPaymentData?.bkashURL)
      }

      SetpayingBookId("")


    }

  return (
    <>
      <Header />
      <div className="" style={{marginTop:80}} >
        
        <DashboardHead 
          screenName="my-bookings"
        />
        
        <div className="content">
          <div className="container">
            
            <div className="row">
              <div className="col-xl-12 col-lg-12 d-flex">
                <div className="card dashboard-card flex-fill">
                  <div className="card-header card-header-info">
                    <div className="card-header-inner">
                      <h4>My Bookings</h4>
                      <p>Boost your game with stats and goals tailored to you</p>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="nav-Court"
                      role="tabpanel"
                      aria-labelledby="nav-Court-tab"
                      tabIndex={0}
                    >
                      <div className="table-responsive dashboard-table-responsive">
                        <table className="table dashboard-card-table">
                          <tbody>
                            {data?.map((ele, index) => 
                              <tr
                                key={index}
                              >
                                <td>
                                  <div className="academy-info">
                                    <Link
                                      href="user-bookings.html"
                                      className="academy-img"
                                    >
                                      <img
                                        src={BASE_URL + "/" + ele?.ground?.gallery[0]}

                                        alt="Booking"
                                      />
                                    </Link>
                                    <div className="academy-content">
                                      <h6>
                                        <a
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#upcoming-court"
                                        >
                                          {ele?.ground?.name}
                                        </a>
                                      </h6>
                                      {/* <span>Court 1</span> */}
                                      <ul>
                                        {/* <li>Guests : 4</li> */}
                                        <li>{ele?.totalSlots} Slots</li>
                                      </ul>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h6>Date &amp; Time</h6>
                                  <p>{moment(ele?.startDate).format("MMM DD, YYYY")}</p>
                                  <p>{moment(ele?.startDate).format("hh:mm A")} - {moment(ele?.endDate).format("hh:mm A")}</p>
                                </td>
                                <td>
                                  <h4>${ele?.totalCharge}</h4>
                                </td>
                                <td>
                                  <h4>{ele?.bookingStatus}</h4>
                                  {/* <div className="dropdown dropdown-action">
                                    <a
                                      href="#"
                                      className="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="feather-x-circle" /> Cancel
                                      </a>
                                    </div>
                                  </div> */}
                                </td>
                                <td>
                                  {ele?.ground?.reqAdvancedAmount && ele.chargePaid < ele?.ground?.reqAdvancedAmount ? 
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => Pay(ele)}
                                      disabled={payingBookId === ele?._id}
                                    >
                                      {payingBookId === ele?._id ? "Loading" : "Pay Advance"}
                                    </button>
                                  : null }
                                  {/* <Link
                                    to={`/booking-detail/${ele?._id}`}
                                    className="btn btn-primary"
                                  >
                                    View Details
                                  </Link> */}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  )
}