import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProfileNav = ({screenName}) => {

  return (
    <div className="coach-court-list profile-court-list">
      <ul className="nav">
        <li>
          <Link className={screenName == "my-profile" ? "active" : ""} to={"/my-profile"} >
            Profile
          </Link>
        </li>
        <li>
          <Link className={screenName == "change-password" ? "active" : ""} to={"/change-password"} >Change Password</Link>
        </li>
        {/* <li>
          <Link className={screenName == "other-settings" ? "active" : ""} to={"/other-settings"} >Other Settings</Link>
        </li> */}
      </ul>
    </div>
  );
};

export default ProfileNav;