import axios from "axios";
import { BASE_URL, Notify } from "../uti/uti";
import { CallAuthGet, CallAuthPost } from "./lib";

export async function BlogBySlug(slug){

    try{
        
        const res = await axios.get(BASE_URL+`/blogs/${slug}`)

        console.log(res)

        if(res?.data?.success){
            return res?.data?.result
        }

        Notify(res?.data?.message, "error")

        return false


    }catch(e){

        console.log(e)

        Notify("Something went wrong", "error")

        return false

    }
    
}

export async function Blogs(searchText){

    try{

        let url = '/blogs'

        if(searchText){
            url = url + '?searchKey='+searchText
        }

        console.log(url)

        
        const res = await axios.get(BASE_URL+url)

        console.log("blog", res)

        if(res?.data?.success){
            return res?.data?.result
        }

        Notify(res?.data?.message, "error")

        return false


    }catch(e){

        console.log(e)

        Notify("Something went wrong", "error")

        return false

    }

}


