import axios from "axios";
import { BASE_URL, Notify } from "../uti/uti";


export async function Login(payload){

    try{
        const res = await axios.post(BASE_URL+'/auth/signin', payload)

        console.log(res)

        if(res?.data?.success){
            return res?.data
        }

        Notify(res?.data?.message, "error")

        return false


    }catch(e){

        Notify("Something went wrong", "error")

        return false

    }

}

export async function SignUp(payload){

    try{
        const res = await axios.post(BASE_URL+'/auth/signup', payload)

        if(res?.data?.success){
            return true
        }

        Notify(res?.data?.message, "error")

        return false

    }catch(e){

        Notify("Something went wrong", "error")

        return false

    }

}

export async function Verify(payload){

    try{
        const res = await axios.post(BASE_URL+'/auth/signup/confirm', payload)

        if(res?.data?.success){
            return res?.data
        }

        Notify(res?.data?.message, "error")

        return false

    }catch(e){

        Notify("Something went wrong", "error")
        
        return false

    }

}



export async function ForgetPassword(payload){

    try{
        const res = await axios.post(BASE_URL+'/auth/forget-password', payload)

        if(res?.data?.success){
            return true
        }

        Notify(res?.data?.message, "error")

        return false

    }catch(e){

        Notify("Something went wrong", "error")

        return false

    }

}



export async function RecoverPassword(payload){

    try{
        const res = await axios.post(BASE_URL+'/auth/recover-password', payload)

        if(res?.data?.success){
            return true
        }

        Notify(res?.data?.message, "error")

        return false

    }catch(e){

        Notify("Something went wrong", "error")

        return false

    }

}
