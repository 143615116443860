import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import APIS from "../apis"
import Footer from "../component/Footer"
import Header from "../component/Header"
import { Notify, OnLoginSuccess } from "../uti/uti"


export default function SignUp() {

    const navigate = useNavigate()

    const [pin, Setpin] = useState("")

    const [showPin, SetshowPin] = useState(false)

    const [showPass, SetshowPass] = useState(false)
    const [showConPass, SetConshowPass] = useState(false)

    const [loading, Setloading] = useState(false)

    const [submitData, SetsubmitData] = useState({
        // name:"Delowar",
        // phone:'+8801940084384',
        // password:"Server123#",
        // conPassword:"Server123#",
        name:"",
        phone:'',
        password:"",
        conPassword:""
    })

    const [reSendCountDown, SetreSendCountDown] = useState(30)

    const [resendingOTP, setresendingOTP] = useState(false)


    useEffect(() => {

        const countDown = setInterval(() => {

            if(reSendCountDown > 0){

                SetreSendCountDown((value) => value > 0 ? value - 1 : 0)

            }
          
        }, 1000);
    
        return () => {
          clearInterval(countDown)
        }
    
    }, [reSendCountDown])

    const Submit  = async (e) => {

        e.preventDefault()

        console.log(submitData, pin)

        if(showPin){

            Setloading(true)

            const res = await APIS.Auth.Verify({
                phone:`+88${submitData?.phone}`,
                otp:pin
            })

            Setloading(false)


            console.log(res)


            if(res){
                Notify("Welcome to Sports Arena")
                OnLoginSuccess(res)
                navigate('/')

            }
            
        }else{

            if(!submitData?.name){
                Notify("Please enter your name", "error")
                return null
            }

            if(!submitData?.phone){
                Notify("Invalid phone number", "error")
                return null
            }

            if(submitData?.phone?.length !== 11){
                Notify("Phone number must be 11 digit", "error")
                return null
            }

            if (isNaN(submitData?.phone))
            {
                Notify("Phone number must be a number", "error")
                return null
            }

            if(!submitData?.password){
                Notify("Please enter a password", "error")
                return null
            }

            if(submitData?.password !== submitData?.conPassword){
                Notify("Confirm password mismatched", "error")
                return null
            }

            Setloading(true)

            const res = await APIS.Auth.SignUp({
                ...submitData,
                ...{phone:`+88${submitData?.phone}`}
            })

            SetreSendCountDown(30)

            Setloading(false)

            console.log(res)

            if(res){
                SetshowPin(true)
            }

        }

        
        
    }

    const ResendOTP = async () => {
        if(reSendCountDown > 0){
            Notify("OTP already sent", "error")
            return null
        }
        setresendingOTP(true)
        const res = await APIS.Auth.SignUp({
            ...submitData,
            ...{phone:`+88${submitData?.phone}`}
        })
        Notify("OTP sent again", "success")
        SetreSendCountDown(30)
        setresendingOTP(false)
    }

    return (
        <>
        <Header />
        <div class=" authendication-pages" style={{marginTop:80}}>
            <div className="content">
                <div className="container wrapper no-padding">
                    <div className="row no-margin vph-100">
                        <div className="row no-margin vph-100 d-flex align-items-center justify-content-center">
                            <div className="col-sm-8 col-md-6 col-lg-6 mx-auto">
                                {/* <header className="text-center">
                                    <a href="user-dashboard.html">
                                        <img src="assets/img/logo-black.svg" className="img-fluid" alt="Logo" />
                                    </a>
                                </header> */}
                                <div className="shadow-card">
                                    <h2>Get Started With Sports Arena</h2>
                                    <p>Ignite your sports journey with Sports Arena and get started now.</p>

                                    <div className="tab-content mt-5" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="user"
                                            role="tabpanel"
                                            aria-labelledby="user-tab"
                                        >
                                            <form onSubmit={Submit} >
                                                {!showPin ?
                                                <div className="form-group">
                                                    <div className="group-img">
                                                        <i className="feather-user" />
                                                        <input
                                                            value={submitData?.name}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Your Name"
                                                            onChange={e => SetsubmitData({...submitData, ...{name:e.target.value}})}
                                                        />
                                                    </div>
                                                </div>
                                                : null }
                                                {!showPin ?
                                                <div className="form-group">
                                                    <div className="group-img">
                                                        <i className="feather-phone" />
                                                        <input
                                                            value={submitData?.phone}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Phone Number"
                                                            onChange={e => SetsubmitData({...submitData, ...{phone:e.target.value}})}
                                                        />
                                                    </div>
                                                </div>
                                                : null }
                                                {!showPin ?
                                                <div className="form-group">
                                                    <div className="pass-group group-img">
                                                        <i 
                                                            onClick={() => SetshowPass(!showPass)}
                                                            className={`toggle-password feather-eye${!showPass ? "-off" : ""}` }
                                                        />
                                                        <input
                                                            value={submitData?.password}
                                                            type={showPass ? "text" : "password"}
                                                            className="form-control pass-input"
                                                            placeholder="Password"
                                                            onChange={e => SetsubmitData({...submitData, ...{password:e.target.value}})}
                                                        />
                                                    </div>
                                                </div>
                                                 : null }
                                                 {!showPin ?
                                                <div className="form-group">
                                                    <div className="pass-group group-img">
                                                        <i 
                                                            onClick={() => SetConshowPass(!showConPass)}
                                                            className={`toggle-password feather-eye${!showConPass ? "-off" : ""}` }
                                                        />
                                                        <input
                                                            value={submitData?.conPassword}
                                                            type={showConPass ? "text" : "password"}
                                                            className="form-control pass-input"
                                                            placeholder="Confirm Password"
                                                            onChange={e => SetsubmitData({...submitData, ...{conPassword:e.target.value}})}
                                                        />
                                                    </div>
                                                </div>
                                                 : null }
                                                {showPin ?
                                                <div className="form-group">
                                                    <div className="pass-group group-img">
                                                        <i className="toggle-password feather-eye-off" />
                                                        <input
                                                            type="text"
                                                            className="form-control pass-input"
                                                            placeholder="OTP Code"
                                                            onChange={e => Setpin(e?.target?.value)}
                                                        />
                                                    </div>
                                                </div>
                                                : null }
                                                <div className="form-group d-sm-flex align-items-center justify-content-between">
                                                    <div className="form-check form-switch d-flex align-items-center justify-content-start">
                                                        {/* <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="user-pass"
                                                        />
                                                        <label className="form-check-label" htmlFor="user-pass">
                                                            Remember Password
                                                        </label> */}
                                                    </div>
                                                    <span>
                                                        {/* <a href="forgot-password.html" className="forgot-pass">
                                                            Forgot Password
                                                        </a> */}
                                                    </span>
                                                </div>
                                                <button
                                                    className="btn btn-secondary register-btn d-inline-flex justify-content-center align-items-center w-100 btn-block"
                                                    type="submit"
                                                    disabled={loading}
                                                >
                                                    {loading ? "Loading..." : showPin ? "Confirm" : "Sign Up"}
                                                    <i className="feather-arrow-right-circle ms-2" />
                                                </button>

                                                {showPin ?
                                                    <>
                                                        <div
                                                            style={{textAlign:'center', cursor:'pointer'}}
                                                            onClick={ResendOTP}
                                                        >
                                                            {resendingOTP ?

                                                                <h6>Resending OTP...</h6>

                                                            :
                                                                <h6 style={{fontWeight:'bold'}} >
                                                                    Didn’t receive OTP?   <span style={{color:"#ff0000", textDecorationLine:'underline'}} >{reSendCountDown ? `Resend OTP in ${reSendCountDown}` : "Resend OTP"}</span>
                                                                </h6>
                                                            }

                                                        </div>

                                                    </>
                                                : null }
                                               
                                            </form>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="bottom-text text-center">
                                    <p>
                                        Have an account? <a href="/login">Sign in!</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}